import { useCallback } from "react";
import ProTable, { ProColumns } from "@ant-design/pro-table";
import {
  ICombo,
  ILookupKeys,
  useFetchSingleItem,
  Endpoints,
  ILookup,
} from "components/api";
import Button from "antd/lib/button";
import tableExport from "antd-table-export";
import Popconfirm from "antd/es/popconfirm";
import Space from "antd/es/space";
import DeleteIcon from "@ant-design/icons/DeleteOutlined";
import { MenuActions } from "./index";

type TableProps = {
  lookups: {
    data: Record<string, ICombo[]>;
    isLoading: boolean;
  };
  hubId: string | null;
  queryKey?: any;
  deleteHandler: (id: string, type: MenuActions) => void;
  editHandler: (payload: ResponseType, type: MenuActions) => void;
};

export type ResponseType = {
  id: string;
  service: ILookup;
  active: boolean;
}

const Table = (props: TableProps) => {
  let endpoint = `${Endpoints.Hubs.services}?hubId=${props.hubId}`;
  const { data, isError } = useFetchSingleItem<ResponseType[]>({
    endpoint,
    ids: null,
    enabled: props.hubId !== null,
  });

  const dataSource = data?.data ?? [];
  const columns = useCallback(
    (
      _lookups: Record<ILookupKeys, ICombo[]>,
      isLoading: boolean
    ): ProColumns<ResponseType>[] => {
      if (isLoading) {
        return [];
      }
      return [
        {
          title: "Service",
          dataIndex: "service",
          valueType: "select",
          request: async () => _lookups["services"],
          render: (_, { service }) => service.name,
          order: 1,
          debounceTime: 0,
          fieldProps: {
            showSearch: true,
          },
        },
        {
          title: "Active",
          dataIndex: "active",
          render: (_, { active }) => active ? "Yes" : "No",
          hideInSearch: true,
        },
        {
          title: "Operations",
          width: 200,
          key: "options",
          valueType: "option",
          render: (_, record) => {
            return (
              <Space size="middle">
                {/* <Button
                  onClick={() => props.editHandler(record, "service")}
                  type="primary"
                  icon={<EditOutlined />}
                /> */}
                <Popconfirm
                  title={`Are you sure to delete the selected service from the system?`}
                  onConfirm={() => props.deleteHandler(record.service.id.toString(), "service")}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    danger
                    type="primary"
                    icon={<DeleteIcon />}
                    title="Delete"
                  />
                </Popconfirm>
              </Space>
            );
          },
        },
      ];
    },
    [props]
  );

  const renderedColumns = columns(props.lookups.data, props.lookups.isLoading);

  const exportInstance = new tableExport(
    dataSource.map((v) => ({
      ...v,
      service: v.service?.name,
    })),
    renderedColumns
      .filter((x) => x.key !== "options")
      .map((x) => ({
        dataIndex: x.dataIndex as string,
        title: x.title! as string,
      }))
  );

  if (isError || !data) {
    return <div>Error</div>;
  }
  return (
    <>
      <ProTable<ResponseType>
        columns={renderedColumns}
        loading={false}
        request={async () => data?.data}
        dataSource={data?.data ?? []}
        rowKey="id"
        search={false}
        dateFormatter="string"
        pagination={false}
        toolBarRender={() => [
          <Button
            type="primary"
            onClick={() => exportInstance.download("Hub Services List", "xlsx")}
          >
            Export
          </Button>,
        ]}
      />
    </>
  );
};

export default Table;
