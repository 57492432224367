import { useNavigate } from "react-router-dom";
import Space from "antd/es/space";
import Menu from "antd/es/menu";
import Dropdown from "antd/es/dropdown";
import Typography from "antd/es/typography";

import UserOutlined from "@ant-design/icons/UserOutlined";
import LogoutOutlined from "@ant-design/icons/LogoutOutlined";

const RightContent = ({
  username,
  logoutHandler,
}: {
  username: string;
  logoutHandler: () => void;
}) => {
  const navigate = useNavigate();
  const menu = (
    <Menu
      onClick={({ key }) => {
        if (key === "logout") {
          return logoutHandler();
        }
        navigate(`/${key}`);
      }}
      items={[
        { key: "account", icon: <UserOutlined />, label: "Account" },
        { key: "logout", icon: <LogoutOutlined />, label: "Logout" },
      ]}
    />
  );
  return (
    <Space direction="horizontal">
      <Dropdown.Button overlay={menu as any} type="text" icon={<UserOutlined />}>
        <Typography.Text
        >
          {username.split(" ")[0]}!
        </Typography.Text>
      </Dropdown.Button>
    </Space>
  );
};

export default RightContent;
