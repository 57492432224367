import { useState } from "react";
import { DrawerForm, ProFormDependency, ProFormSelect } from "@ant-design/pro-form";
import message from "antd/es/message";

import Error from "components/ui/error";
import { Endpoints, ICombo, ISubmissionResponse } from "components/api";
import DependentSelect from "components/ui/fields/dependent-select";

export type EmailRequestType = {
  ids: [];
  subject: string;
  message: string;
  action: 'information'
};

type FormProps = {
  visible: boolean;
  onVisibleChange: (visible: boolean) => void;
  lookups: Record<string, ICombo[]>;
  onFinish: (values: EmailRequestType) => Promise<ISubmissionResponse>;
  title: string;
};

const ActionsForm = (props: FormProps) => {
  const { visible, onVisibleChange, onFinish } = props;
  const [formErrors, setFormErrors] = useState<ISubmissionResponse>({
    error: null,
    isError: false,
    isSuccess: false,
  });

  const resetErrors = () => {
    if (formErrors.isError) {
      setFormErrors({
        error: null,
        isError: false,
        isSuccess: true,
      });
    }
  };

  return (
    <DrawerForm<EmailRequestType>
      title={props.title}
      size={"middle"}
      width={550}
      open={visible}
      autoFocusFirstInput
      request={async () => ({ ids: [], message: "", subject: "", action: "information" })}
      name="galawo-model-form"
      drawerProps={{
        onClose: () => onVisibleChange(false),
        destroyOnClose: true,
        closable: false,
        maskClosable: false,
      }}
      onFinish={async (values) => {
        var result = await onFinish(values);
        setFormErrors(result);
        if (result.isSuccess) {
          message.success("Successfully completed");
          onVisibleChange(false);
        }
        return result.isSuccess;
      }}
      onChange={resetErrors}
      submitter={{
        searchConfig: {
          resetText: "Cancel",
          submitText: "Submit",
        },
      }}
    >
      <ProFormSelect
        name="branchId"
        label="Branch"
        rules={[{ required: true }]}
        width="md"
        request={async () => props.lookups.branches ?? []}
        debounceTime={0}
        showSearch={true}
        placeholder="Please select a Branch"
        dependencies={["ids"]}
      />
      <ProFormDependency name={["branchId"]}>
        {({ branchId }) => {
          return (
            <DependentSelect
              dependentTo="branchId"
              name="ids"
              label="Employee"
              rules={[{ required: true }]}
              width="md"
              debounceTime={0}
              showSearch={true}
              placeholder="Please Choose Employee Names"
              lookupId={branchId}
              mode="multiple"
              dependentLookupProps={{
                endpoint: Endpoints.Employee.lookup,
                fromLookup: false,
                isLocalEnum: false,
                id: "employees",
                search: "includeSupportUsers=false&branchId",
              }}
            />
          );
        }}
      </ProFormDependency>
      <ProFormSelect
        name="templateId"
        label="Email Template"
        rules={[{ required: true }]}
        width="md"
        request={async () => props.lookups.templates ?? []}
        debounceTime={0}
        showSearch={true}
        placeholder="Please select an Email Template"
      />
      <Error error={formErrors.error} isError={formErrors.isError} />
    </DrawerForm>
  );
};

export default ActionsForm;
