import { useState } from "react";
import { LoginForm, ProFormText } from "@ant-design/pro-form";
import Button from "antd/es/button";
import LockOutlined from "@ant-design/icons/LockOutlined";
import Error from "components/ui/error";

import { Endpoints, IErrorResult, ISubmissionResponse, useMutator } from "components/api";
import Space from "antd/es/space";
import { useContext } from "react";
import { AuthContext } from "components/auth";
export type submitLoginResponse = {
  data: {
    token: string;
  };
};

type ChangePasswordType = {
  currentPassword: string;
  newPassword: string;
};

const Login = ({ logoutHandler, currentPassword }: { logoutHandler: VoidFunction, currentPassword: string }) => {
  const mutation = useMutator<ChangePasswordType>();
  const [isLoading, setisLoading] = useState<boolean>(false);
  const { company } = useContext(AuthContext);

  const [formErrors, setFormErrors] = useState<ISubmissionResponse>({
    error: null,
    isError: false,
    isSuccess: false,
  });

  const resetErrors = () => {
    if (formErrors.isError) {
      setFormErrors({
        error: null,
        isError: false,
        isSuccess: true,
      });
    }
  };

  return (
    <div style={{ padding: "10vh" }}>
      <div style={{ backgroundColor: "white" }}>
        <LoginForm<ChangePasswordType>
          // logo={<CarFilled style={{ fontSize: "40px" }} />}
          title={`${company?.name} - Change Password`}
          subTitle={company?.name}
          onFinish={async (values) => {
            setisLoading(true);
            try {
              const result = await mutation.addHandler({
                endpoint: Endpoints.Account.ChangePassword,
                mainQueryKey: [],
                payload: { ...values, currentPassword },
              });

              if (result.isError) {
                setFormErrors(result);
              } else {
                logoutHandler();
              }
            } catch (error: any) {
              setFormErrors({
                isError: true,
                isSuccess: false,
                error: error as IErrorResult,
              });
            } finally {
              setisLoading(false);
            }
          }}
          onChange={resetErrors}
          submitter={{
            render: ({ submit }) => (
              <Space align="center">
                <Button
                  loading={isLoading}
                  size="large"
                  type="primary"
                  block
                  onClick={submit}
                >
                  Login
                </Button>
                <Button
                  size="large"
                  type="primary"
                  block
                  onClick={logoutHandler}
                >
                  Logout
                </Button>
              </Space>
            )
          }}
        >
          <ProFormText.Password
            name="newPassword"
            fieldProps={{
              size: "large",
              prefix: <LockOutlined className={"prefixIcon"} />,
            }}
            placeholder="Type your new password"
            rules={[
              {
                required: true,
                message: "New Password is required!",
              },
            ]}
          />
          <Error error={formErrors.error} isError={formErrors.isError} />
        </LoginForm>
      </div>
    </div>
  );
};

export default Login;
