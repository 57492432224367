import Row from "antd/es/row";
import Col from "antd/es/col";
import Descriptions from "antd/es/descriptions";

import Layout from "antd/es/layout";
import Button from "antd/es/button";
import Flex from "antd/es/flex";
import { useNavigate } from "react-router-dom";



import { ResponseType } from "pages/hubs";
import * as api from "components/api";
import ActionMenu from "./action_menu";
import BackwardOutlined from "@ant-design/icons/BackwardOutlined";
import Typography from "antd/es/typography";

const { Header } = Layout;

type profileHeaderProps = {
  data: ResponseType;
  options: Record<api.ILookupKeys, api.ICombo[]>;
  title: string;
  handler: (event: any) => void;
  canAdd: boolean;
};

export const ProfileHeader = ({
  data,
  title,
  handler,
  canAdd,
}: profileHeaderProps) => {
  const navigate = useNavigate();
  return (
    <Header
      style={{
        backgroundColor: "white",
        padding: "20px 0",
        height: "100px",
        marginBottom: "20px",
      }}
    >
      <Row>
        <Col span={24}>
          <Flex justify="space-between" align="center">
            <Flex justify="flex-end" align="center">
              <Button onClick={() => navigate(-1)} icon={<BackwardOutlined />} />
              <Typography.Title style={{ paddingTop: 0, marginTop: 0, paddingLeft: 12 }} level={5}>{`${title} ${data.name}`}</Typography.Title>
            </Flex>
            <div>
              <ActionMenu handleClick={handler} />
            </div>
          </Flex>
        </Col>
        <Col span={24}>
          <Descriptions size="small" column={3}>
            <Descriptions.Item label="Branch">
              {data.branch.name}
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    </Header>
  );
};
