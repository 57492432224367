import { ILookup, ILookupKeys } from "components/api";
const discriminator = "ILookup";
const Sex: ILookup[] = [
  { id: 1, name: "Male", discriminator },
  { id: 2, name: "Female", discriminator },
];
const Countries: ILookup[] = [
  { id: "so", name: "Somalia", discriminator },
  { id: "sl", name: "Somaliland", discriminator },
  { id: "eg", name: "Egypt", discriminator },
];

const ServiceTypes: ILookup[] = [
  { id: 1, name: "Rate", discriminator },
  { id: 2, name: "Text", discriminator },
];

const Colors: ILookup[] = [
  { id: "green", name: "Green", discriminator },
  { id: "blue", name: "Blue", discriminator },
  { id: "black", name: "Black", discriminator },
  { id: "red", name: "Red", discriminator },
  { id: "yellow", name: "Yellow", discriminator },
  { id: "orange", name: "Orange", discriminator },
  { id: "purple", name: "Purple", discriminator },
  { id: "cyan", name: "Cyan", discriminator },
  { id: "lime", name: "Lime", discriminator },
  { id: "volcano", name: "Volcano", discriminator },
  { id: "magenta", name: "Magenta", discriminator },
  { id: "geekblue", name: "Geek blue", discriminator },
  { id: "gold", name: "Gold", discriminator },
  { id: "pink", name: "Pink", discriminator },
];

const FullNamePolicies: ILookup[] = [
  { id: 1, name: "First Name First", discriminator },
  { id: 2, name: "Second Name First", discriminator },
];

const EmailType: ILookup[] = [
  { id: "information", name: "Information", discriminator },
  { id: "password reset", name: "Password Reset", discriminator },
  { id: "otp", name: "One Time Password (OTP)", discriminator },
];

const values: { key: ILookupKeys; ILookups: ILookup[] }[] = [
  { key: "sex", ILookups: Sex },
  { key: "countries", ILookups: Countries },
  { key: "colors", ILookups: Colors },
  { key: "fullNamePolicies", ILookups: FullNamePolicies },
  { key: "serviceTypes", ILookups: ServiceTypes },
  { key: "emailType", ILookups: EmailType },
];

const enumValues = (key: string) =>
  values.find((x) => x.key === key)?.ILookups || [];
export default enumValues;
