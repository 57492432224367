import Button from "antd/es/button";
import Dropdown from "antd/es/dropdown";
import Menu from "antd/es/menu";
type ActionMenuProps = {
  handleClick: (event: any) => void;
  active: boolean;
};
const ActionMenu = ({ active, handleClick }: ActionMenuProps) => {
  const menuItems = [{ key: "claims", label: "Add Claims" }];
  const menu = <Menu onClick={handleClick} items={menuItems} />;
  return (
    <Dropdown overlay={menu} placement="bottomRight" arrow>
      <Button block type="primary" size="middle">
        Actions
      </Button>
    </Dropdown>
  );
};

export default ActionMenu;
