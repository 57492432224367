import { useCallback } from "react";
import ProTable, { ProColumns } from "@ant-design/pro-table";
import {
  ICombo,
  ILookupKeys,
  useFetchSingleItem,
  Endpoints,
} from "components/api";
import Button from "antd/lib/button";
import tableExport from "antd-table-export";
import { ResponseType, starEquivalentDescriptions } from "../feedbacks/types";
import { MenuActions } from "./index";

type TableProps = {
  lookups: {
    data: Record<string, ICombo[]>;
    isLoading: boolean;
  };
  roId: string | null;
  queryKey?: any;
  deleteHandler: (id: string, type: MenuActions) => void;
  editHandler: (payload: ResponseType, type: MenuActions) => void;
};

const Table = (props: TableProps) => {
  let endpoint = `${Endpoints.Feedback.main}?roId=${props.roId}`;
  const { data, isError } = useFetchSingleItem<ResponseType[]>({
    endpoint,
    ids: null,
    enabled: props.roId !== null,
  });

  const dataSource = data?.data ?? [];
  const columns = useCallback(
    (
      _lookups: Record<ILookupKeys, ICombo[]>,
      isLoading: boolean
    ): ProColumns<ResponseType>[] => {
      if (isLoading) {
        return [];
      }
      return [
        {
          title: "Phone",
          dataIndex: "accountNo",
          valueType: "text",
          hideInSearch: true,
        },
        {
          title: "Rate",
          dataIndex: "rate",
          valueType: "rate",
          fieldProps: {
            allowHalf: false,
            tooltips: starEquivalentDescriptions
          },
          render: (dom, { rate }) => {
            return (
              <>
                {dom}
                {"  " + starEquivalentDescriptions[rate - 1]}
              </>
            )
          }
        },
        {
          title: "Note",
          dataIndex: "note",
          valueType: "text",
          hideInSearch: true,
          render: (_, { note, note2 }) => note === "Other" ? note2 : note
        },

        {
          title: "Date",
          dataIndex: "date",
          valueType: "date",
          order: 5,
        },
      ];
    },
    []
  );

  const renderedColumns = columns(props.lookups.data, props.lookups.isLoading);

  const exportInstance = new tableExport(
    dataSource.map((v) => ({
      ...v,
      hub: v.hub?.name,
      branch: v.branch?.name,
      date: new Date(v.date).toLocaleDateString(),
      ro: v.ro?.name,
    })),
    renderedColumns
      .filter((x) => x.key !== "options")
      .map((x) => ({
        dataIndex: x.dataIndex as string,
        title: x.title! as string,
      }))
  );

  if (isError || !data) {
    return <div>Error</div>;
  }
  return (
    <>
      <ProTable<ResponseType>
        columns={renderedColumns}
        loading={false}
        request={async () => data?.data}
        dataSource={data?.data ?? []}
        rowKey="id"
        search={false}
        dateFormatter="string"
        pagination={false}
        toolBarRender={() => [
          <Button
            type="primary"
            onClick={() => exportInstance.download("Hub Services List", "xlsx")}
          >
            Export
          </Button>,
        ]}
      />
    </>
  );
};

export default Table;
