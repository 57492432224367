import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import ProTable, { ProColumns } from "@ant-design/pro-table";
import Button from "antd/es/button";
import Space from "antd/es/space";
import Popconfirm from "antd/es/popconfirm";
import EditButton from "@ant-design/icons/EditOutlined";
import DeleteButton from "@ant-design/icons/DeleteOutlined";
import ViewButton from "@ant-design/icons/EyeOutlined";
import tableExport from "antd-table-export";

import { ResponseType, TableProps } from "./types";
import { ICombo, ILookupKeys } from "components/api";
import { RoleBasedAuthorization, useAuth } from "components/auth";

const Table = (props: TableProps) => {
  const {
    state: { user },
  } = useAuth();

  let canAdd = RoleBasedAuthorization(user, ["CEmp"], false);
  let canEdit = RoleBasedAuthorization(user, ["UEmp"], false);
  let canDelete = RoleBasedAuthorization(user, ["DEmp"], false);
  let CanSendNotifications = true;

  const navigate = useNavigate();
  const { isLoading, total, pageSize, dataSource, SendRequestForData } = props;
  const columns = useCallback(
    (
      lookups: Record<ILookupKeys, ICombo[]>,
      isLoading: boolean
    ): ProColumns<ResponseType>[] => {
      if (isLoading) {
        return [];
      }
      return [
        {
          title: "Name",
          dataIndex: "fullName",
          hideInSearch: true,
        },
        {
          title: "Username",
          dataIndex: "username",
          hideInSearch: true,
        },
        {
          title: "Sex",
          dataIndex: "sex",
          valueType: "select",
          request: async () => lookups["sex"],
          render: (_, { sex }) => sex.name,
          order: 1,
          hideInSearch: true,
          hideInTable: true,
        },
        {
          title: "Email",
          dataIndex: "email",
          hideInTable: true,
          hideInSearch: true,
        },
        {
          title: "Phone",
          dataIndex: "phone",
        },
        {
          title: "Active",
          dataIndex: "active",
          valueType: "switch",
          render: (_, { active }) => (active ? "Yes" : "No"),
          hideInSearch: true,
        },
        {
          title: "Address",
          dataIndex: "address",
          hideInSearch: true,
          hideInTable: true,
        },
        {
          title: "Title", dataIndex: "title",
          hideInSearch: true,
        },
        {
          title: "Notes",
          dataIndex: "notes",
          hideInSearch: true,
          hideInTable: true,
        },
        {
          title: "Recycle Bin",
          dataIndex: "deleted",
          valueType: "switch",
          hideInTable: true,
        },
        {
          title: "Operations",
          width: 200,
          key: "options",
          valueType: "option",
          render: (_, record) => {
            const showActions =
              record.username !== "Admin";
            return showActions ? (
              <Space size="middle">
                <Button
                  onClick={() =>
                    navigate({
                      pathname: "details",
                      search: "id=" + record.id,
                    })
                  }
                  type="default"
                  icon={<ViewButton />}
                  title="Details"
                />
                {canDelete && (
                  <Popconfirm
                    title={`Are you sure to  delete "${record.firstName ?? "this"
                      }" from the system?`}
                    onConfirm={() => props.deleteHandler(record.id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      danger
                      type="primary"
                      icon={<DeleteButton />}
                      title="Delete"
                    />
                  </Popconfirm>
                )}
                {canEdit && (
                  <Button
                    onClick={() => props.editHandler(record)}
                    type="primary"
                    icon={<EditButton />}
                    title="Edit"
                  />
                )}
              </Space>
            ) : null;
          },
        },
      ];
    },
    [canDelete, canEdit, navigate, props]
  );
  const renderedColumns = columns(props.lookups.data, props.lookups.isLoading);

  const exportInstance = new tableExport(
    dataSource.map((v) => ({
      ...v,
      sex: v.sex.name,
      active: v.active ? "Yes" : "No",
      deleted: v.deleted ? "Yes" : "No",
    })),
    renderedColumns
      // remove options, deleted and notes columns
      .filter((x) => x.dataIndex !== "Operations" && x.dataIndex !== "deleted" && x.dataIndex !== "notes")
      .map((x) => ({
        dataIndex: x.dataIndex as string,
        title: x.title! as string,
      }))
  );
  return (
    <ProTable<ResponseType>
      headerTitle="Employees List"
      columns={renderedColumns}
      loading={isLoading}
      request={async (params = {}, sort, filter) =>
        SendRequestForData({ params, sort, filter })
      }
      onReset={() => {
        let params = { current: 1, pageSize };
        let sort = {};
        let filter = {};
        SendRequestForData({ params, sort, filter, isReset: true });
      }}
      dataSource={dataSource}
      rowKey="id"
      pagination={{
        showQuickJumper: true,
        pageSize,
        total,
        pageSizeOptions: ["10", "50", "100", "200", "300", "400", "500"],
      }}
      search={{ labelWidth: "auto" }}
      dateFormatter="string"
      toolBarRender={() => [
        <Button
          type="primary"
          onClick={() => exportInstance.download("Employees List", "xlsx")}
        >
          Export
        </Button>,
        canAdd && (
          <Button type="primary" onClick={props.addNewHandler}>
            Add New
          </Button>
        ),
        CanSendNotifications && (
          <Button type="primary" onClick={props.sendEmailHandler}>
            Send Email
          </Button>
        ),
      ]}
    />
  );
};

export default Table;
