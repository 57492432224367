import { useNavigate, useLocation } from "react-router-dom";

import {
  Endpoints,
  useQuery,
  useLookups,
  useMutator,
} from "components/api";
import Header from "components/ui/header";
import { IChangeRequestProps } from "pages/common";
import { useState } from "react";
import { paginateEndpoint } from "utils";
import Table from "./table";
import { RequestType, ResponseType } from "./types";

const endpoint = Endpoints.Employee.tokens;

const Categories = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [locationData, setLocationData] = useState<string>("");

  const { data, isLoading, refetch, error, isError, mainQueryKey } = useQuery<
    ResponseType[]
  >({ endpoint });
  const options = useLookups({
    lookups: [
      {
        endpoint: Endpoints.Employee.tokens,
        fromLookup: false,
        isLocalEnum: false,
        id: "employees",
      },
      {
        endpoint: Endpoints.Branches.lookup,
        fromLookup: false,
        isLocalEnum: false,
        id: "branches",
      },
    ],
    enabled: true,
  });
  const mutations = useMutator<RequestType | ResponseType>();

  const deleteOnClickHandler = async (id: number | string | undefined) => {
    return await mutations.deleteHandler({
      mainQueryKey: mainQueryKey,
      endpoint: endpoint + "/" + id,
    });
  };

  const SendRequestForData = ({
    params,
    sort,
    filter,
    isReset,
  }: IChangeRequestProps) => {
    const newLogaction = paginateEndpoint({
      endpoint,
      includeEndpoint: false,
      requestParams: { params, sort, filter },
      dependent: undefined,
      outsiderDependent: false,
    });

    if (locationData !== newLogaction) {
      setLocationData(newLogaction);
      navigate({
        pathname: pathname,
        search: newLogaction,
      });
    }

    return data?.data ?? [];
  };

  return (
    <>
      <Header
        title="Active Logins"
        error={error?.detail ?? ""}
        isError={isError}
        isLoading={isLoading}
        refetch={refetch}
      />
      <Table
        SendRequestForData={SendRequestForData}
        dataSource={data?.data ?? []}
        deleteHandler={deleteOnClickHandler}
        isLoading={isLoading}
        pageSize={data?.size ?? 10}
        total={data?.count ?? 0}
        lookups={{
          data: options.data,
          isLoading: options.isLoading,
        }}
        addNewHandler={() => { }}
        editHandler={() => { }}

      />
    </>
  );
};

export default Categories;
