import { useLocation } from "react-router-dom";
import * as api from "components/api";
import { ProfileHeader } from "./header";
import { ResponseType as ROResponseType } from "pages/ROs";
import DetailsTab from "./tabs";
import { Endpoints, ISubmissionResponse } from "components/api";
import { useState } from "react";
import ServiceForm from "./service-form";
import { RoleBasedAuthorization, useAuth } from "components/auth";
import { ResponseType as HubServiceResponseType } from '../feedbacks/types';
import message from "antd/es/message";
export type MenuActions = "service";
const lookupQueries: api.ILookupQueryProps[] = [
  {
    endpoint: Endpoints.Services.lookup,
    fromLookup: false,
    isLocalEnum: false,
    id: "services",
  },
];

const Index = () => {
  const {
    state: { user },
  } = useAuth();

  let canAddUnit = RoleBasedAuthorization(user, ["CHS"], false);
  const params = new URLSearchParams(useLocation().search);
  const mutations = api.useMutator<any>();

  const endpoint = api.Endpoints.ROs.main;
  const roId = params.get("id");

  const options = api.useLookups({ lookups: lookupQueries });
  const { isLoading, data, mainQueryKey } =
    api.useFetchSingleItem<ROResponseType>({
      endpoint,
      ids: [roId ?? ""],
      enabled: roId !== null,
    });
  const [serviceForm, setServiceForm] = useState<{
    visible: boolean;
    type: "service";
    payload: any;
    formType: "Add New" | "Edit";
  }>({
    visible: false,
    type: "service",
    payload: {
      id: roId ?? "0",
      serviceId: 0,
    },
    formType: "Add New",
  });

  const editHandler = (payload: HubServiceResponseType, key: MenuActions) => {
    switch (key) {
      case "service":
        setServiceForm({
          visible: true,
          type: "service",
          payload: {
            ...payload,
            serviceId: payload.hub.id as number,
          },
          formType: "Edit",
        });
        break;
      default:
        throw new Error("Clicked On unknown menu button");
    }
  };
  const submit = async (
    payload: any
  ): Promise<ISubmissionResponse> => {
    try {
      if (serviceForm.visible && serviceForm.type === "service") {
        const dest = api.Endpoints.Hubs.services;
        const mainQueryKey = `${Endpoints.Hubs.services}?roId=${roId}`;
        if (serviceForm.formType === "Add New") {
          return await mutations.addHandler({
            endpoint: dest,
            mainQueryKey: [mainQueryKey],
            payload: {
              ...payload,
              id: roId ?? "0",
            },
          });
        } else {
          return await mutations.editHandler({
            endpoint: dest,
            mainQueryKey: [mainQueryKey],
            payload: {
              ...payload,
              id: roId ?? "0",
            },
          });
        }
      } else {
        const dest = "";
        return await mutations.addHandler({
          endpoint: dest,
          mainQueryKey: [dest],
          payload,
        });
      }
    } catch (error) {
      return {
        isError: true,
        isSuccess: false,
        error: error as api.IErrorResult,
      };
    } finally {
      mutations.invalidateQuery(mainQueryKey);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (isLoading || !data) {
    return <div>Loading...</div>;
  }

  const handlerMenuClick = ({ key }: { key: MenuActions }) => {
    switch (key) {
      case "service":
        setServiceForm({
          visible: true,
          type: "service",
          payload: {
            id: roId ?? "0",
            serviceId: 0,
          },
          formType: "Add New",
        });
        break;
      default:
        throw new Error("Clicked On unknown menu button");
    }
  };

  const deleteHandler = async (id: number | string, type: MenuActions) => {
    try {
      switch (type) {
        case "service": {
          const dest = api.Endpoints.Hubs.RemoveServices(
            roId!,
            id.toString()
          );
          await mutations.deleteHandler({
            endpoint: dest,
            mainQueryKey: [
              `${api.Endpoints.Hubs.services}?roId=${roId!}`,
              null,
            ],
          });
          message.success("Successfully completed");
          break;
        }

        default:
          break;
      }
    } catch (error) {
      const err = error as api.IErrorResult;
      message.error(err.detail ?? err.title ?? "Error");
    }
  };

  return (
    <>
      <ProfileHeader
        title="RO Details"
        data={data.data}
        options={options.data}
        handler={handlerMenuClick}
        canAdd={canAddUnit}
      />
      <DetailsTab
        roId={roId}
        lookups={{
          data: options.data,
          isLoading: options.isLoading,
        }}
        queryKey={mainQueryKey}
        editHandler={editHandler}
        deleteHandler={deleteHandler}
      />
      <ServiceForm
        lookups={options.data}
        visible={serviceForm.visible}
        title={"Service"}
        onFinish={submit}
        onClose={() => setServiceForm((e) => ({ ...e, visible: false }))}
        defaultValue={serviceForm.payload}
        type={serviceForm.formType}
      />
    </>
  );
};

export default Index;
