import TeamOutlined from "@ant-design/icons/TeamOutlined";
import BarChartOutlined from "@ant-design/icons/BarChartOutlined";

import { IUser, RoleBasedAuthorization } from "components/auth";
import * as types from "./types";
import data from "./data.json";
import { SystemRoles } from "assets";

type MenuGroupType = {
  name: "Settings" | "Performance" | "Dashboard";
  path: string;
  icon: JSX.Element;
  description: string;
  access: any;
};

const appMenuList: types.IAppMenuItem[] = data as types.IAppMenuItem[];
export const groups: MenuGroupType[] = [
  {
    name: "Settings",
    description: "Application Settings",
    path: "/settings",
    icon: <TeamOutlined />,
    access: SystemRoles.filter((x) => x.group === "settings").map((x) => x.key),
  },
  {
    name: "Performance",
    description: "Employee Performance",
    path: "/performance",
    icon: <BarChartOutlined />,
    access: SystemRoles.filter((x) => x.group === "performance").map(
      (x) => x.key
    ),
  },
];

const listMenu = (user: IUser | undefined): types.IAppMenuList[] => {
  let list: types.IAppMenuList[] = groups
    .filter((x) => RoleBasedAuthorization(user, x.access))
    .map((group) => {
      return {
        name: group.name.toString(),
        icon: group.icon,
        path: group.path,
        access: group.access,
        routes: RoleBasedAuthorization(user, group.access)
          ? appMenuList.filter(
              (x) =>
                x.group === group.name && RoleBasedAuthorization(user, x.access)
            )
          : undefined,
      };
    });
  return list.filter((x) => x.routes !== undefined);
};

export default listMenu;
