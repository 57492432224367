import { ProFormSelect } from "@ant-design/pro-form";
import { ProFieldRequestData } from "@ant-design/pro-utils";
import { Rule } from "antd/lib/form";
import { ICombo, ILookupQueryProps, dependentLookup } from "components/api";

type dependentSelectProps = {
  name: string;
  label: string;
  placeholder?: string;
  lookupId: string;
  dependentTo: string;
  rules?: Rule[];
  readonly?: boolean;
  showSearch?: boolean;
  debounceTime?: number;
  options?: ICombo[];
  width?: number | "sm" | "md" | "xl" | "xs" | "lg";
  mode?: "multiple" | "single";
  request?: ProFieldRequestData<ICombo>;
  dependencies?: string[];
  dependentLookupProps?: ILookupQueryProps;
  radioType?: "button" | "radio";
  layout?: "vertical" | "horizontal";
  min?: number;
  max?: number;
  shouldUpdate?: boolean;
  fieldProps?: any;
  showBalance?: boolean;
};
const DependentSelect = (props: dependentSelectProps) => {
  const { dependentTo, lookupId, dependentLookupProps, ...fieldProps } = props;

  if (!dependentLookupProps) {
    return (
      <div>
        <p>dependentLookupProps can't be null</p>
      </div>
    );
  }
  const { state } = dependentLookup(dependentLookupProps, lookupId);
  const { isError, isLoading, data } = state;
  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (isError) {
    return <div>Error Loading Props</div>;
  }
  return (
    <ProFormSelect
      {...fieldProps}
      options={data?.data ?? []}
      disabled={lookupId === undefined || isLoading}
      fieldProps={{
        optionItemRender(item: any) {
          if (props.showBalance) {
            return item?.label + " - " + item?.balance;
          }
          return item?.label;
        },
      }}
    />
  );
};

export default DependentSelect;
