import dbiLogo from 'assets/Logo/DBI-Logo.png';
import eabLogo from 'assets/Logo/EAB-Logo.png';
import { ICompany } from "components/auth";
type CompanyResponseType = {
    data: ICompany
};
const companies: ICompany[] = [
    {
        id: "dbi",
        name: "Dahabshiil Bank International",
        email: "info@dahabshilbank.com",
        logo: dbiLogo
    },
    {
        id: "dmt",
        name: "Dahabshiil Money Transfer",
        email: "info@dahabshiil.com",
        logo: eabLogo
    },
    {
        id: "eab",
        name: "East Africa Bank",
        email: "info@eastafricabank.com",
        logo: eabLogo
    },
    {
        id: "sn",
        name: "Somtel Network",
        email: "info@dahabshiil.com",
        logo: eabLogo
    }
]
export const getCompany = (): CompanyResponseType => {
    const location = window.location.href;
    const companyId = location.split("/")[3];
    console.log('companyId', companyId);
    const data = companies.find((company) => company.id === companyId);

    return {
        data: data ?? companies[0]
    };
};






