import Button from "antd/es/button";
import Dropdown from "antd/es/dropdown";
import Menu from "antd/es/menu";
import { RoleBasedAuthorization, useAuth } from "components/auth";
type ActionMenuProps = {
  handleClick: (event: any) => void;
  active: boolean;
};
const ActionMenu = ({ active, handleClick }: ActionMenuProps) => {
  const {
    state: { user },
  } = useAuth();

  let canChangePass = RoleBasedAuthorization(user, ["CEmpP"], false);
  let canAddToRole = RoleBasedAuthorization(user, ["AE2R"], false);
  let canSendEmail = true;

  const menuItems = [{ key: "revoke", label: "Revoke Active Logins" }];
  if (canChangePass) {
    menuItems.push({ key: "changePass", label: "Reset Password" });
  }
  if (canAddToRole) {
    menuItems.push({ key: "roles", label: "Add Role" });
  }

  if (canSendEmail) {
    menuItems.push({ key: "email", label: "Send Email" });
  }

  const menu = <Menu onClick={handleClick} items={menuItems} />;
  return (
    <Dropdown overlay={menu} placement="bottomRight" arrow>
      <Button block type="primary" size="middle">
        Actions
      </Button>
    </Dropdown>
  );
};

export default ActionMenu;
