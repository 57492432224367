import Button from "antd/es/button";
import Result from "antd/es/result";
import Spin from "antd/es/spin";
type loadingProps = {
  msg?: string;
  margin?: string;
  actionHandler?: () => void;
  actionTitle?: string;
};
const loading = ({
  msg = "You are not authorized to access this app",
  margin = "30% auto",
  actionHandler,
  actionTitle,
}: loadingProps) => {
  return (
    <div style={{ margin }}>
      <Result
        icon={<Spin size="large" />}
        title={msg}
        extra={
          <Button onClick={actionHandler} type="primary">
            {actionTitle}
          </Button>
        }
      />
    </div>
  );
};

export default loading;
