import Tabs from "antd/es/tabs";
import { ICombo } from "components/api";
import { MenuActions } from ".";
import PermissionsTable from "./permissions-table";

import { ResponseType } from "pages/roles//types";

export type tapPageProps = {
  employeeId: string | null;
  lookups: { data: Record<string, ICombo[]>; isLoading: boolean };
  data: ResponseType;
  deleteHandler: (id: number | string, type: MenuActions, mainQueryKey: string) => void;
  editHandler: (payload: any, type: MenuActions) => void;
};

const ProfileTabs = (props: tapPageProps) => {
  const tableProps = {
    employeeId: props.employeeId,
    lookups: props.lookups,
    deleteHandler: props.deleteHandler,
    editHandler: props.editHandler,
  };
  return (
    <Tabs
      defaultActiveKey="1"
      items={[
        {
          key: "1",
          label: "Permissions",
          children: <PermissionsTable {...tableProps} />,
        },
      ]}
    />
  );
};

export default ProfileTabs;
