import { useState } from "react";
import {
  DrawerForm,
  ProFormText,
  ProFormSelect,
} from "@ant-design/pro-form";
import message from "antd/es/message";

import { RequestType, FormProps } from "./types";
import { ISubmissionResponse } from "components/api";
import Error from "components/ui/error";
const Form = (props: FormProps) => {
  const { state, title, visible, onVisibleChange, initialState, onFinish } =
    props;
  const [formErrors, setFormErrors] = useState<ISubmissionResponse>({
    error: null,
    isError: false,
    isSuccess: false,
  });

  const resetErrors = () => {
    if (formErrors.isError) {
      setFormErrors({
        error: null,
        isError: false,
        isSuccess: true,
      });
    }
  };
  return (
    <DrawerForm<RequestType>
      title={`${state} ${title}`}
      size={"middle"}
      width={550}
      open={visible}
      onOpenChange={onVisibleChange}
      autoFocusFirstInput
      request={async () => initialState as RequestType}
      name="galawo-model-form"
      drawerProps={{
        onClose: () => onVisibleChange(false),
        destroyOnClose: true,
        closable: false,
        maskClosable: false,
      }}
      onFinish={async (values) => {
        const { ...rest } = values;
        var result = await onFinish({
          ...rest,
        });
        if (result.isSuccess) {
          message.success("Successfully completed");
        }
        setFormErrors(result);
        return result.isSuccess;
      }}
      onChange={resetErrors}
      submitter={{
        searchConfig: {
          resetText: "Cancel",
          submitText: "Submit",
        },
      }}
    >
      <ProFormSelect
        name="branchId"
        label="Branch"
        rules={[{ required: true }]}
        width="md"
        request={async () => props.lookups.data["branches"] ?? []}
        debounceTime={0}
        showSearch={true}
        placeholder="Please select Branch"
      />
      <ProFormText
        name="name"
        label="Name"
        placeholder="Enter Name"
        rules={[{ required: true, min: 2, max: 50 }]}
        width="md"
      />
      <Error error={formErrors.error} isError={formErrors.isError} />
    </DrawerForm>
  );
};

export default Form;
