import { useState } from "react";
import { DrawerForm } from "@ant-design/pro-form";
import message from "antd/es/message";

import Error from "components/ui/error";
import { ICombo, ILookupKeys, ISubmissionResponse } from "components/api";
import { RORequestType } from '../ROs/form';
import { ProFormText, ProFormTimePicker } from "@ant-design/pro-components";

type FormProps = {
  visible: boolean;
  onClose: (visible: boolean) => void;
  lookups: Record<ILookupKeys, ICombo[]>;
  onFinish: (values: RORequestType) => Promise<ISubmissionResponse>;
  title: string;
  defaultValue: RORequestType;
  type: "Add New" | "Edit";
};
const RolesForm = (props: FormProps) => {
  const { visible, onClose, onFinish } = props;
  const [formErrors, setFormErrors] = useState<ISubmissionResponse>({
    error: null,
    isError: false,
    isSuccess: false,
  });

  const resetErrors = () => {
    if (formErrors.isError) {
      setFormErrors({
        error: null,
        isError: false,
        isSuccess: true,
      });
    }
  };

  return (
    <DrawerForm<RORequestType>
      title={props.title}
      size={"middle"}
      width={400}
      open={visible}
      autoFocusFirstInput
      request={async () => props.defaultValue}
      name="galawo-model-form"
      drawerProps={{
        onClose: () => onClose(false),
        destroyOnClose: true,
        closable: false,
        maskClosable: false,
      }}
      onFinish={async ({ ...values }) => {
        var result = await onFinish({
          ...values,
        });
        setFormErrors(result);
        if (result.isSuccess) {
          message.success("Successfully completed");
          onClose(false);
        }
        return result.isSuccess;
      }}
      onChange={resetErrors}
      submitter={{
        searchConfig: {
          resetText: "Cancel",
          submitText: "Submit",
        },
      }}
    >
      <ProFormText
        name="name"
        label="Name"
        placeholder="Enter Name"
        rules={[{ required: true, min: 2, max: 50 }]}
        width="md"
      />
      <ProFormTimePicker
        name="startTime"
        label="Start Time"
        placeholder="Enter Start Time"
        rules={[{ required: true }]}
        width="md"
      />
      <ProFormTimePicker
        name="endTime"
        label="End Time"
        placeholder="Enter End Time"
        rules={[{ required: true }]}
        width="md"
      />
      <Error error={formErrors.error} isError={formErrors.isError} />
    </DrawerForm>
  );
};

export default RolesForm;