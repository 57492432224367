import Row from "antd/es/row";
import Descriptions from "antd/es/descriptions";
import { ResponseType } from "pages/employees";
type profileHeaderProps = {
  data: ResponseType;
};

const PersonalIfno = ({ data }: profileHeaderProps) => {
  return (
    <Row>
      <Descriptions size="small" column={3} title="Info">
        <Descriptions.Item label="Address">{data.address}</Descriptions.Item>
        <Descriptions.Item label="Phone">{data.phone}</Descriptions.Item>
      </Descriptions>
      <Descriptions size="small" column={2} title="Contact">
        <Descriptions.Item label="Job Title">{data?.phone}</Descriptions.Item>
        <Descriptions.Item label="Email">{data.email}</Descriptions.Item>
      </Descriptions>
    </Row>
  );
};

export default PersonalIfno;
