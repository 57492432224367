import { useState } from "react";
import { DrawerForm, ProFormSelect, ProFormSwitch, ProFormText, ProFormTextArea } from "@ant-design/pro-form";
import message from "antd/es/message";

import { RequestType, FormProps } from "./types";
import { ISubmissionResponse } from "components/api";
import Error from "components/ui/error";
const Form = (props: FormProps) => {
  const { state, title, visible, onVisibleChange, initialState, onFinish } =
    props;
  const [formErrors, setFormErrors] = useState<ISubmissionResponse>({
    error: null,
    isError: false,
    isSuccess: false,
  });

  const resetErrors = () => {
    if (formErrors.isError) {
      setFormErrors({
        error: null,
        isError: false,
        isSuccess: true,
      });
    }
  };

  return (
    <DrawerForm<RequestType>
      title={`${state} ${title}`}
      size={"middle"}
      width={550}
      open={visible}
      onOpenChange={onVisibleChange}
      autoFocusFirstInput
      request={async () => initialState}
      name="galawo-model-form"
      drawerProps={{
        onClose: () => onVisibleChange(false),
        destroyOnClose: true,
        closable: false,
        maskClosable: false,
      }}
      onFinish={async (values) => {
        var result = await onFinish(values);
        if (result.isSuccess) {
          message.success("Successful completed");
        }
        setFormErrors(result);
        return result.isSuccess;
      }}
      onChange={resetErrors}
      submitter={{
        searchConfig: {
          resetText: "Cancel",
          submitText: "Submit",
        },
      }}
    >
      <ProFormText
        name="name"
        label="Name"
        placeholder="Enter Name"
        rules={[{ required: true, min: 2, max: 100 }]}
        width="md"
      />
      <ProFormSelect
        name="type"
        label="Type"
        rules={[{ required: true }]}
        width="md"
        request={async () => props.lookups.data["serviceTypes"] ?? []}
        debounceTime={0}
        showSearch={true}
        placeholder="Please select Type"
      />
      <ProFormSwitch
        name="optional"
        label="Optional"
        placeholder="Enter Optional"
        rules={[{ required: false }]}
        width="md"
      />
      <ProFormTextArea
        name="description"
        label="Description"
        placeholder="Enter Description"
        rules={[{ required: false, max: 500 }]}
        width="md"
      />
      <Error error={formErrors.error} isError={formErrors.isError} />
    </DrawerForm>
  );
};

export default Form;
