import { IErrorResult } from "components/api";
import Typography from "antd/es/typography";
import CloseCircleOutlined from "@ant-design/icons/CloseCircleOutlined";
const { Text, Paragraph } = Typography;

const ErrorComponent = ({
  isError,
  error,
}: {
  isError: boolean;
  error: IErrorResult | null;
}) => {
  if (!isError || !error) {
    return null;
  }
  const { detail, errors } = error;
  let errorRender;
  if (errors) {
    const list = Object.values(errors);
    errorRender = list.map((err, i) => (
      <Paragraph key={i}>
        <CloseCircleOutlined color={"red"} className="error-result-icon" />{" "}
        {err.join(" ")}
      </Paragraph>
    ));
  }
  return (
    <div className="desc">
      <Paragraph>
        <Text
          strong
          style={{
            fontSize: 16,
          }}
        >
          {detail}
        </Text>
      </Paragraph>
      {errorRender}
    </div>
  );
};

export default ErrorComponent;
