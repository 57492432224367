import Layout from "antd/es/layout";
import Button from "antd/es/button";
import Typography from "antd/es/typography";
import Flex from "antd/es/flex";
import { useNavigate } from "react-router-dom";
import BackwardOutlined from "@ant-design/icons/BackwardOutlined";

const { Header } = Layout;

const AppHeader = ({
  title,
  isLoading,
  isError,
  error,
  refetch,
  children
}: {
  title: string;
  isLoading: boolean;
  isError: boolean;
  error: string;
  refetch: () => void;
  children?: React.ReactNode;
}) => {
  const navigate = useNavigate();
  return (
    <Header
      style={{
        backgroundColor: "white",
        padding: "20px 0",
        height: "100px",
      }}
    >
      <Flex justify="space-between" align="center">
        <Flex justify="flex-end" align="center">
          <Button onClick={() => navigate(-1)} icon={<BackwardOutlined />} />
          <Typography.Title style={{ paddingTop: 0, marginTop: 0, paddingLeft: 12 }} level={5}>{title}</Typography.Title>
        </Flex>
        <Button onClick={refetch}>Reload</Button>
      </Flex>
      {children}
    </Header>
  );
};

export default AppHeader;
