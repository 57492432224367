import { useNavigate, useLocation } from "react-router-dom";

import {
  Endpoints,
  ISubmissionResponse,
  useQuery,
  useMutator,
  IErrorResult,
  useLookups,
} from "components/api";
import { IChangeRequestProps } from "pages/common";
import { useState } from "react";
import { paginateEndpoint } from "utils";
import Form from "./form";
import Table from "./table";
import { RequestType, ResponseType, FormStateProps } from "./types";

const endpoint = Endpoints.Services.main;
const Services = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [formState, setFormState] = useState<FormStateProps>({
    visible: false,
    addForm: true,
    initialState: { id: "", name: "", description: "", typeId: "", optional: false },
  });

  const options = useLookups({
    lookups: [
      {
        endpoint: "serviceTypes",
        fromLookup: false,
        isLocalEnum: true,
        id: "serviceTypes",
      },
    ],
    enabled: true,
  });

  const [locationData, setLocationData] = useState<string>("");

  const { data, isLoading, mainQueryKey } = useQuery<
    ResponseType[]
  >({ endpoint });

  const mutations = useMutator<RequestType | ResponseType>();

  const submit = async (payload: RequestType): Promise<ISubmissionResponse> => {
    try {
      if (formState.addForm) {
        return await mutations.addHandler({
          endpoint,
          mainQueryKey,
          payload,
        });
      } else {
        return await mutations.editHandler({
          endpoint: endpoint,
          mainQueryKey: mainQueryKey,
          payload: {
            ...payload,
            id: formState.initialState.id,
          },
        });
      }
    } catch (error) {
      return {
        isError: true,
        isSuccess: false,
        error: error as IErrorResult,
      };
    }
  };

  const formVisibleChange = (
    visible: boolean,
    init = { id: "", name: "", description: "", typeId: "", optional: false },
    addForm = true
  ) => {
    if (formState.visible === visible) {
      return;
    }
    setFormState({
      visible,
      addForm,
      initialState: init,
    });
  };

  const editOnClickHandler = (payload: ResponseType) => {
    console.log('payload', payload);
    formVisibleChange(true, {
      ...payload,
      typeId: payload.type?.id as string ?? "",
    }, false)
  };
  const deleteOnClickHandler = async (id: number | string | undefined) => {
    return await mutations.deleteHandler({
      mainQueryKey: mainQueryKey,
      endpoint: endpoint + "/" + id,
    });
  };

  const SendRequestForData = ({
    params,
    sort,
    filter,
    isReset,
  }: IChangeRequestProps) => {
    const newLogaction = paginateEndpoint({
      endpoint,
      includeEndpoint: false,
      requestParams: { params, sort, filter },
      dependent: undefined,
      outsiderDependent: false,
    });

    if (locationData !== newLogaction) {
      setLocationData(newLogaction);
      navigate({
        pathname: pathname,
        search: newLogaction,
      });
    }

    return data?.data ?? [];
  };

  return (
    <>
      <Table
        SendRequestForData={SendRequestForData}
        addNewHandler={() => formVisibleChange(true)}
        dataSource={data?.data ?? []}
        deleteHandler={deleteOnClickHandler}
        editHandler={editOnClickHandler}
        isLoading={isLoading}
        pageSize={data?.size ?? 10}
        total={data?.count ?? 0}
        lookups={{
          data: options.data,
          isLoading: options.isLoading,
        }}
      />
      <Form
        title="Service"
        initialState={formState.initialState}
        lookups={{
          data: options.data,
          isLoading: options.isLoading,
        }}
        onFinish={submit}
        onVisibleChange={(visible) => formVisibleChange(visible)}
        state={formState.addForm ? "Add New" : "Edit"}
        visible={formState.visible}

      />
    </>
  );
};

export default Services;
