const baseDomain = process.env.REACT_APP_DOMAIN;

export const baseUrl = baseDomain + "/api/v1";
export const staleTime: number = 18000;
export const mobileWidth = 768;
export const ipadWidth = 884;
export const tokenInStorageKey: string = "tokens";
export type AppColors =
  | "success"
  | "processing"
  | "error"
  | "default"
  | "warning"
  | "pink"
  | "red"
  | "yellow"
  | "orange"
  | "cyan"
  | "green"
  | "blue"
  | "purple"
  | "geekblue"
  | "magenta"
  | "volcano"
  | "gold"
  | "lime";
export const tokenRefreshleeway = {
  ms: 120000,
  sc: 120,
};

export const dateTimeFormats = {
  dateTime: "DD MMM, YYYY HH:mm:ss",
  onlyDate: "DD MMM, YYYY",
  onlyTime: "HH:mm:ss",
  onlyYear: "YYYY",
};

export const toAddIdSuffix = [
  "branch",
  "employee",
  "goalStatus",
  "item",
  "achievement",
  "goal",
  "goalChunk",
  "Category",
  "hub",
  "ro",
];

export const SystemRoles = [
  {
    key: "CreateUserAccount",
    type: "CUA",
    text: "Create User Account",
    group: "settings",
  },
  {
    key: "UpdateUserInformation",
    type: "UUI",
    text: "Update User Info",
    group: "settings",
  },
  {
    key: "DeactivateUserAccount",
    type: "DUA",
    text: "Deactivate User Account",
    group: "settings",
  },
  {
    key: "AddUserToRole",
    type: "AU2R",
    text: "Add User To Role",
    group: "settings",
  },
  {
    key: "RemoveUserFromRole",
    type: "RUFR",
    text: "Remove User From Role",
    group: "settings",
  },
  {
    Key: "ResetUserAccount",
    type: "RUA",
    text: "Reset User Account",
    group: "settings",
  },
  {
    Key: "ResetUserLogins",
    type: "RUL",
    text: "Reset User Logins",
    group: "settings",
  },
  // Roles
  { key: "CreateRole", type: "CR", text: "Create Role", group: "settings" },
  { key: "RemoveRole", type: "RR", text: "Remove Role", group: "settings" },
  { key: "QueryRole", type: "QR", text: "Query Role", group: "settings" },
  { key: "UpdateRole", type: "UR", text: "Update Role", group: "settings" },
  {
    key: "AddClaimTORole",
    type: "AC2R",
    text: "Add Claim To Role",
    group: "settings",
  },
  {
    key: "RemoveClaimFromRole",
    type: "RCFR",
    text: "Remove Claim From Role",
    group: "settings",
  },
  // Employees
  {
    key: "CreateEmployee",
    type: "CEmp",
    text: "Create Employee",
    group: "settings",
  },
  {
    key: "UpdateEmployee",
    type: "UEmp",
    text: "Update Employee",
    group: "settings",
  },
  {
    key: "DeleteEmployee",
    type: "DEmp",
    text: "Delete Employee",
    group: "settings",
  },
  {
    key: "ReadEmployee",
    type: "REmp",
    text: "Read Employee",
    group: "settings",
  },
  {
    key: "TransferEmployee",
    type: "TEmp",
    text: "Transfer Employee",
    group: "settings",
  },
  {
    key: "ChangeEmployeeActivity",
    type: "AEmp",
    text: "Change Activity",
    group: "settings",
  },
  {
    key: "ChangeEmployeePassword",
    type: "CEmpP",
    text: "Change Employee Password",
    group: "settings",
  },
  {
    key: "AddEmployeeToRole",
    type: "AE2R",
    text: "Add Employee To Role",
    group: "settings",
  },
  {
    key: "RemoveEmployeeToRole",
    type: "RE2R",
    text: "Remove Employee From Role",
    group: "settings",
  },

  // Branches
  {
    key: "CreateBranch",
    type: "CB",
    text: "Create Branch",
    group: "settings",
  },
  {
    key: "UpdateBranch",
    type: "UB",
    text: "Update Branch",
    group: "settings",
  },
  {
    key: "DeleteBranch",
    type: "DB",
    text: "Delete Branch",
    group: "settings",
  },
  {
    key: "ReadBranch",
    type: "RB",
    text: "Read Branch",
    group: "settings",
  },
  // Ros
  {
    key: "CreateRo",
    type: "CRo",
    text: "Create Ro",
    group: "settings",
  },
  {
    key: "UpdateRo",
    type: "URo",
    text: "Update Ro",
    group: "settings",
  },
  {
    key: "DeleteRo",
    type: "DRo",
    text: "Delete Ro",
    group: "settings",
  },
  {
    key: "ReadRo",
    type: "RRo",
    text: "Read Ro",
    group: "settings",
  },
  // feedbacks
  {
    key: "ReadFeedback",
    type: "RF",
    text: "Read Feedback",
    group: "settings",
  },
  // Services

  {
    key: "CreateService",
    type: "CB",
    text: "Create Service",
    group: "settings",
  },
  {
    key: "UpdateService",
    type: "UB",
    text: "Update Service",
    group: "settings",
  },
  {
    key: "DeleteService",
    type: "DB",
    text: "Delete Service",
    group: "settings",
  },
  {
    key: "ReadService",
    type: "RB",
    text: "Read Service",
    group: "settings",
  },
  // Hubs
  {
    key: "CreateHub",
    type: "CH",
    text: "Create Hub",
    group: "settings",
  },
  {
    key: "UpdateHub",
    type: "UH",
    text: "Update Hub",
    group: "settings",
  },
  {
    key: "DeleteHub",
    type: "DH",
    text: "Delete Hub",
    group: "settings",
  },
  {
    key: "ReadHub",
    type: "RH",
    text: "Read Hub",
    group: "settings",
  },

  // Customers
  {
    key: "CreateCustomer",
    type: "CCu",
    text: "Create Customer",
    group: "settings",
  },
  {
    key: "UpdateCustomer",
    type: "UCu",
    text: "Update Customer",
    group: "settings",
  },
  {
    key: "DeleteCustomer",
    type: "DCu",
    text: "Delete Customer",
    group: "settings",
  },
  {
    key: "ReadCustomer",
    type: "RCu",
    text: "Read Customer",
    group: "settings",
  },

  // logins
  {
    key: "ReadUserLogins",
    type: "RULs",
    text: "Read User Logins",
    group: "settings",
  },

  // Company
  {
    key: "CreateCompany",
    type: "CCo",
    text: "Create Company",
    group: "settings",
  },
  {
    key: "UpdateCompany",
    type: "UCo",
    text: "Update Company",
    group: "settings",
  },
  {
    key: "DeleteCompany",
    type: "DCo",
    text: "Delete Company",
    group: "settings",
  },
  {
    key: "ReadCompany",
    type: "RCo",
    text: "Read Company",
    group: "settings",
  },
  {
    key: "AddEmailTemplate",
    type: "AET",
    text: "Add Email Template",
    group: "settings",
  },
  {
    key: "EditEmailTemplate",
    type: "EET",
    text: "Edit Email Template",
    group: "settings",
  },
  {
    key: "ReadEmailTemplate",
    type: "RET",
    text: "Read Email Template",
    group: "settings",
  },
  {
    key: "DeleteEmailTemplate",
    type: "DET",
    text: "Delete Email Template",
    group: "settings",
  },

  // logins
  {
    key: "ReadUserLogins",
    type: "RULs",
    text: "Read User Logins",
    group: "performance",
  },
];
