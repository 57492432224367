import { DefaultFooter } from "@ant-design/pro-layout";
import { useContext } from "react";
import { AuthContext } from "components/auth";
const Footer = () => {
  const { company } = useContext(AuthContext);
  return (
    <DefaultFooter
      links={[{ key: "home", title: company?.name, href: "" }]}
      copyright={"Dahabshiil Group. All rights reserved."}
    />
  );
};

export default Footer;
