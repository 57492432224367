import { useQuery } from "react-query";
import { staleTime } from "assets/";
import {
  ILookup,
  IResponse,
  ILookupQueryProps,
  useAxios,
} from "components/api";
function Lookups_Depen(
  props: ILookupQueryProps,
  dependentId: number | string | undefined
) {
  const { endpoint, fromLookup } = props;
  const axios = useAxios();
  const search =
    props.search && dependentId ? `?${props.search}=${dependentId}` : undefined;
  // if (props.otherDependents) {
  //   props.search += `&${props.otherDependents}`;
  // }
  const { isLoading, isError, data, error, isFetching } = useQuery(
    [endpoint, { search }],
    async () => {
      const { data } = await axios.get<IResponse<ILookup[]>>(
        `/${endpoint}${fromLookup ? "/lookup" : ""}${search ?? ""}`
      );
      return {
        data: data.data.map((x) => ({
          label: x.name,
          value: x.id,
          code: x.code,
          balance: x.balance,
        })),
      };
    },
    {
      keepPreviousData: true,
      retry: false,
      staleTime,
      enabled: search !== undefined,
    }
  );

  return {
    state: { isLoading, isError, data, error, isFetching },
  };
}
export default Lookups_Depen;
