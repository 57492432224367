import Row from "antd/es/row";
import Descriptions from "antd/es/descriptions";

import { ResponseType } from "pages/roles/types";
import * as api from "components/api";
import { PageHeader } from "@ant-design/pro-components";
import { Space } from "antd";
import ActionMenu from "./action_menu";

type profileHeaderProps = {
  data: ResponseType;
  options: Record<api.ILookupKeys, api.ICombo[]>;
  title: string;
  handler: (event: any) => void;
};

export const ProfileHeader = ({ data, title, handler }: profileHeaderProps) => {
  return (
    <PageHeader
      onBack={() => window.history.back()}
      title={title}
      subTitle={"Role Details"}
      extra={
        <Space>
          <ActionMenu handleClick={handler} active={true} />
        </Space>
      }
    >
    <Row>
      <Descriptions size="small" column={3}>
        <Descriptions.Item label="Name">{data.name}</Descriptions.Item>
      </Descriptions>
    </Row>
    </PageHeader>
  );
};
