import { useState } from "react";
import { useLocation } from "react-router-dom";
import message from "antd/lib/message";

import * as api from "components/api";
import { ProfileHeader } from "./header";
import { ResponseType } from "pages/roles/types";
import DetailsTab from "./tabs";
import RolesForm, { RoleRequestType } from "./permissions-form";
import { ILookup } from "components/api";
export type MenuActions = "claims";

export type JobType = {
  salary: number;
  startDate: Date;
  endDate: Date;
  job: ILookup;
  type: ILookup;
};
const lookupQueries: api.ILookupQueryProps[] = [
  {
    endpoint: api.Endpoints.Permissions.lookup,
    fromLookup: false,
    isLocalEnum: false,
    id: "claimValues",
  },
];

const Index = () => {
  const params = new URLSearchParams(useLocation().search);
  const endpoint = api.Endpoints.Roles.main;
  const roleId = params.get("id");

  const [roleForm, setRoleForm] = useState({
    visible: false,
    type: "claims",
    payload: {
      type: [],
    },
  });

  const options = api.useLookups({ lookups: lookupQueries });
  const mutations = api.useMutator<any>();
  const { isLoading, data, mainQueryKey } =
    api.useFetchSingleItem<ResponseType>({
      endpoint,
      ids: [roleId ?? ""],
      enabled: roleId !== null,
    });

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (isLoading || !data) {
    return <div>Loading...</div>;
  }
  const handlerMenuClick = ({ key }: { key: MenuActions }) => {
    switch (key) {
      case "claims":
        setRoleForm({
          visible: true,
          type: "claims",
          payload: { type: [] },
        });
        break;
      default:
        throw new Error("Clicked On unknown menu button");
    }
  };
  const submit = async (
    payload: RoleRequestType
  ): Promise<api.ISubmissionResponse> => {
    try {
      if (roleForm.visible) {
        const dest = api.Endpoints.Roles.bulkClaims;
        const mqk = [`${api.Endpoints.Roles.main}/${roleId!}/claims`, null];
        return await mutations.addHandler({
          endpoint: dest,
          mainQueryKey: mqk,
          payload: {
            list: payload?.type,
            roleId,
          },
        });
      } else {
        const dest = "";
        return await mutations.addHandler({
          endpoint: dest,
          mainQueryKey: [dest],
          payload,
        });
      }
    } catch (error) {
      return {
        isError: true,
        isSuccess: false,
        error: error as api.IErrorResult,
      };
    } finally {
      mutations.invalidateQuery(mainQueryKey);
    }
  };

  const deleteHandler = async (id: number | string, type: MenuActions, mainQueryKey: any) => {
    try {
      switch (type) {
        case "claims": {
          const dest = api.Endpoints.Roles.removeClaims(
            roleId!,
            id.toString(),
          );
          await mutations.deleteHandler({
            endpoint: dest,
            mainQueryKey,
          });
          message.success("Successfully completed");
          break;
        }

        default:
          break;
      }
    } catch (error) {
      const err = error as api.IErrorResult;
      message.error(err.detail ?? err.title ?? "Error");
    }
  };
  const editHandler = (payload: any, type: MenuActions) => { };
  return (
    <>
      <ProfileHeader
        title="Role Profile"
        data={data.data}
        options={options.data}
        handler={handlerMenuClick}
      />
      <DetailsTab
        employeeId={roleId}
        lookups={{
          data: options.data,
          isLoading: isLoading,
        }}
        deleteHandler={deleteHandler}
        editHandler={editHandler}
        data={data.data}
      />
      <RolesForm
        lookups={options.data}
        visible={roleForm.visible}
        title={"Roles"}
        onFinish={submit}
        defaultValue={roleForm.payload}
        onClose={() => setRoleForm((e) => ({ ...e, visible: false }))}
      />
    </>
  );
};

export default Index;
