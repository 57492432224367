import { useState } from "react";
import { useLocation } from "react-router-dom";
import message from "antd/lib/message";

import * as api from "components/api";
import { ProfileHeader } from "./header";
import { ResponseType } from "pages/employees";
import DetailsTab from "./tabs";
import { ActionsRequestType } from "./actions-form";
import PositionsForm, { PositionRequestType } from "./position-form";
import RolesForm, { RoleRequestType } from "./roles-form";
import EmailForm, { EmailRequestType } from "./send-email";
import { PopUpActions } from "pages/common";
import { ILookup } from "components/api";
export type MenuActions =
  | "gPass"
  | "roles"
  | "activate"
  | "deactivate"
  | "changePass"
  | "transfer"
  | "email"
  | "revoke";

export type JobType = {
  salary: number;
  startDate: Date;
  endDate: Date;
  job: ILookup;
  type: ILookup;
};
const lookupQueries: api.ILookupQueryProps[] = [
  {
    endpoint: "sex",
    fromLookup: false,
    isLocalEnum: true,
    id: "sex",
  },
  {
    endpoint: api.Endpoints.Roles.lookup,
    fromLookup: false,
    isLocalEnum: false,
    id: "roles",
  },
  {
    endpoint: api.Endpoints.Templates.lookup,
    fromLookup: false,
    isLocalEnum: false,
    id: "templates",
  },
];
type PopUpType = {
  id: number | string;
  title: string;
  visible: boolean;
  type: "activate" | "deactivate" | "revoke" | "changePass";
};
const Index = () => {
  const params = new URLSearchParams(useLocation().search);
  const endpoint = api.Endpoints.Employee.main;
  const employeeId = params.get("id");
  // const [actionForm, setActionForm] = useState({
  //   visible: false,
  //   type: "gPass",
  // });
  const [roleForm, setRoleForm] = useState({
    visible: false,
    type: "roles",
    payload: {
      roleId: "",
    },
  });

  const [emailForm, setEmailForm] = useState({
    visible: false,
    type: "email",
    payload: {
      employeeId: employeeId!,
    },
  });

  const [pSForm, setPSForm] = useState({
    visible: false,
    payload: {
      id: 0,
      branchId: 0,
      employeeId: employeeId!,
    },
    type: "add",
  });

  const [showPopup, setShowPopup] = useState<PopUpType>({
    id: "",
    title: "",
    visible: false,
    type: "activate",
  });
  const options = api.useLookups({ lookups: lookupQueries });
  const mutations = api.useMutator<ActionsRequestType | any>();
  const { isLoading, data, mainQueryKey } =
    api.useFetchSingleItem<ResponseType>({
      endpoint,
      ids: [employeeId ?? ""],
      enabled: employeeId !== null,
    });

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (isLoading || !data) {
    return <div>Loading...</div>;
  }
  const handlerMenuClick = ({ key }: { key: MenuActions }) => {
    switch (key) {
      case "roles":
        setRoleForm({
          visible: true,
          type: "roles",
          payload: { roleId: "" },
        });
        break;
      case "email":
        setEmailForm({
          visible: true,
          type: "email",
          payload: { employeeId: employeeId! },
        });
        break;
      case "changePass":
        setShowPopup({
          visible: true,
          id: "",
          title: "Are you sure to reset this account password",
          type: "changePass",
        });
        break;
      case "transfer":
        setPSForm({
          visible: true,
          type: "add",
          payload: { id: 0, branchId: 0, employeeId: employeeId! },
        });
        break;
      case "activate":
        setShowPopup({
          visible: true,
          id: "",
          title: "Are you sure to activate this account",
          type: "activate",
        });
        break;
      case "deactivate":
        setShowPopup({
          visible: true,
          id: "",
          title: "Are you sure to deactivate this account",
          type: "deactivate",
        });
        break;
      case "revoke":
        setShowPopup({
          visible: true,
          id: "",
          title:
            "Are you sure to revoke all active logins related this account",
          type: "revoke",
        });
        break;
      default:
        throw new Error("Clicked On unknown menu button");
    }
  };
  const submit = async (
    payload: ActionsRequestType | PositionRequestType | EmailRequestType | RoleRequestType
  ): Promise<api.ISubmissionResponse> => {
    try {
      if (roleForm.visible) {
        const dest = api.Endpoints.Employee.addToRole(employeeId!);
        const mqk = [
          `${api.Endpoints.Roles.main}?employeeId=${employeeId!}`,
          null,
        ];
        return await mutations.addHandler({
          endpoint: dest,
          mainQueryKey: mqk,
          payload: {
            ...payload,
            employeeId,
          },
        });
      } else if (emailForm.visible) {
        const dest = api.Endpoints.Notifications.main;
        return await mutations.addHandler({
          endpoint: dest,
          mainQueryKey: [],
          payload: {
            ...payload,
            ids: [employeeId],
          },
        });
      } else if (pSForm.visible) {
        const dest = api.Endpoints.Employee.transfer;
        const mqk = [api.Endpoints.Employee.history(employeeId!), null];
        let res = await mutations.addHandler({
          endpoint: dest,
          mainQueryKey: mainQueryKey,
          payload: {
            ...payload,
            id: employeeId,
          },
        });
        mutations.invalidateQuery(mqk);
        return res;
      } else {
        const dest = "";
        return await mutations.addHandler({
          endpoint: dest,
          mainQueryKey: [dest],
          payload,
        });
      }
    } catch (error) {
      return {
        isError: true,
        isSuccess: false,
        error: error as api.IErrorResult,
      };
    } finally {
      mutations.invalidateQuery(mainQueryKey);
    }
  };
  const confirmPopup = async () => {
    try {
      if (showPopup.visible) {
        switch (showPopup.type) {
          case "activate": {
            await mutations.addHandler({
              endpoint: api.Endpoints.Employee.activate(employeeId!),
              mainQueryKey: mainQueryKey,
            });
            message.success("Successfully completed");
            break;
          }
          case "changePass": {
            await mutations.addHandler({
              endpoint: api.Endpoints.Employee.changePassword,
              payload: { employeeId },
              mainQueryKey: mainQueryKey,
            });
            message.success("Successfully completed");
            break;
          }
          case "deactivate": {
            await mutations.addHandler({
              endpoint: api.Endpoints.Employee.deactivate(employeeId!),
              mainQueryKey: mainQueryKey,
            });
            message.success("Successfully completed");
            break;
          }
          case "revoke": {
            await mutations.addHandler({
              endpoint: api.Endpoints.Employee.revoke(employeeId!),
              mainQueryKey: mainQueryKey,
            });
            message.success("Successfully completed");
            break;
          }
          default:
            break;
        }
      }
    } catch (error) {
      const err = error as api.IErrorResult;
      message.error(err.detail ?? err.title ?? "Error");
    } finally {
      setShowPopup({ ...showPopup, visible: false });
    }
  };
  const deleteHandler = async (id: number | string, type: MenuActions) => {
    try {
      switch (type) {
        case "roles": {
          const dest = api.Endpoints.Employee.removeFromRole(
            employeeId!,
            id.toString()
          );
          await mutations.deleteHandler({
            endpoint: dest,
            mainQueryKey: [
              `${api.Endpoints.Roles.main}?employeeId=${employeeId!}`,
              null,
            ],
          });
          message.success("Successfully completed");
          break;
        }

        default:
          break;
      }
    } catch (error) {
      const err = error as api.IErrorResult;
      message.error(err.detail ?? err.title ?? "Error");
    }
  };
  const editHandler = (payload: any, type: MenuActions) => { };
  return (
    <>
      <ProfileHeader
        title="Employee Profile"
        data={data.data}
        options={options.data}
        handler={handlerMenuClick}
      />
      <DetailsTab
        employeeId={employeeId}
        lookups={{
          data: options.data,
          isLoading: isLoading,
        }}
        deleteHandler={deleteHandler}
        editHandler={editHandler}
        data={data.data}
      />
      <RolesForm
        lookups={options.data}
        visible={roleForm.visible}
        title={"Roles"}
        onFinish={submit}
        defaultValue={roleForm.payload}
        onClose={() => setRoleForm((e) => ({ ...e, visible: false }))}
      />
      <EmailForm
        lookups={options.data}
        visible={emailForm.visible}
        title={"Notifications"}
        onFinish={submit}
        // defaultValue={emailForm.payload}
        onClose={() => setEmailForm((e) => ({ ...e, visible: false }))}
      />
      <PositionsForm
        lookups={options.data}
        visible={pSForm.visible}
        title={"Position"}
        onFinish={submit}
        defaultValue={pSForm.payload}
        onClose={() => setPSForm((e) => ({ ...e, visible: false }))}
      />
      <PopUpActions
        closable={false}
        title={showPopup.title}
        id={showPopup.id}
        visible={showPopup.visible}
        width={340}
        visibilityChangeFunc={() =>
          setShowPopup({ ...showPopup, visible: false })
        }
        onConfirm={confirmPopup}
      />
    </>
  );
};

export default Index;
