import { LoginForm, ProFormDigit, ProFormText } from "@ant-design/pro-form";
import Button from "antd/es/button";
import UserOutlined from "@ant-design/icons/UserOutlined";
import LockOutlined from "@ant-design/icons/LockOutlined";
// import CarFilled from "@ant-design/icons/SecurityScanFilled";
import LoginArt from "assets/Logo/Login Page Art.png";
import { IErrorResult, IResponse, ISubmissionResponse, loginFormType } from "components/api";
import { OTPFormType } from "components/api/types/loginFormType";
import message from "antd/es/message";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Card from "antd/es/card";
import Error from "components/ui/error";
import { useEffect, useState } from "react";
import { ITokens } from "components/auth";
import Space from "antd/es/space";
import Typography from "antd/es/typography";

export type submitLoginResponse = {
  data: {
    token: string;
  };
};

const Login = ({
  loginHandler,
  verifyHandler,
  loading,
  type,
  resendHandler,
  logo,
  title,
  supportEmail,
}: {
  loginHandler: (values: loginFormType) => Promise<IResponse<IErrorResult | ITokens | string>>;
  verifyHandler: (values: OTPFormType) => Promise<IResponse<IErrorResult | ITokens | string>>;
  resendHandler: () => Promise<IResponse<IErrorResult | ITokens | string>>;
  loading: boolean;
  type: "login" | "verify";
  logo: any;
  title: string;
  supportEmail: string;
}) => {
  const [timer, setTimer] = useState(60);
  const [formErrors, setFormErrors] = useState<ISubmissionResponse>({
    error: null,
    isError: false,
    isSuccess: false,
  });

  const resetErrors = () => {
    if (formErrors.isError) {
      setFormErrors({
        error: null,
        isError: false,
        isSuccess: true,
      });
    }
  };

  useEffect(() => {

    let interval = setInterval(() => {
      if (timer > 0 && type === 'login') {
        setTimer((prevTimer) => prevTimer - 1);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [type, timer]);
  const handleResend = async () => {
    if (timer === 0) {
      var result = await resendHandler();
      if (result?.isError) {
        setFormErrors({
          isError: true,
          isSuccess: false,
          error: result.data as IErrorResult,
        });
      } else {
        setTimer(60);
      }
    }
    else {
      message.warning(`You can resend OTP in ${timer} seconds`)
    }
  }
  const form =
    type === "verify" ? (
      <>
        <ProFormText
          name="username"
          fieldProps={{
            size: "large",
            prefix: <UserOutlined className={"prefixIcon"} />,
          }}
          placeholder="Type your username"
          rules={[
            {
              required: true,
              message: "username is required!",
            },
          ]}
        />
        <ProFormText.Password
          name="password"
          fieldProps={{
            size: "large",
            prefix: <LockOutlined className={"prefixIcon"} />,
          }}
          placeholder="Type your password"
          rules={[
            {
              required: true,
              message: "Password is required!",
            },
          ]}
        />
      </>
    ) : (
      <ProFormDigit
        name="OTP"
        fieldProps={{
          size: "large",
          prefix: <LockOutlined className={"prefixIcon"} />,
        }}
        placeholder="Type your OTP"
        rules={[
          {
            required: true,
            message: "OTP is Required!",
          },
        ]}
      />
    );
  const loginButton = (submit: any) => {
    const buttons = [
      {
        loading,
        size: "large",
        type: "primary",
        block: true,
        onClick: submit,
        children: "Login",
        style: {},
        visible: true
      },
      {
        size: "large",
        type: "primary",
        block: true,
        onClick: handleResend,
        children: timer === 0 ? "Resend OTP" : `Resend OTP in ${timer} seconds`,
        style: {},
        visible: type === "login"
      },
    ]

    return (
      <Space style={{ width: "100%" }} align="start" wrap>
        {
          buttons.filter(x => x.visible).map((btn, i) => <Button
            key={i}
            loading={btn.loading}
            type={btn.type as any}
            block={btn.block}
            onClick={btn.onClick}
            style={btn.style}
          >{btn.children}</Button>)
        }
      </Space>
    );
  }

  return (
    <div style={{ padding: "10vh" }}>
      <Row style={{ backgroundColor: "white" }}>
        <Col xs={{ span: 24 }} md={{ span: 14 }} lg={{ span: 12 }}>
          <LoginForm<loginFormType | OTPFormType>
            logo={logo}
            title={title}
            subTitle={type === "verify" ? "Login" : "2FA Verification"}
            onFinish={async (values) => {
              if (type === "verify") {
                const result = await loginHandler(
                  values as loginFormType
                );
                if (result?.isError) {
                  setFormErrors({
                    isError: true,
                    isSuccess: false,
                    error: result.data as IErrorResult,
                  });
                  setTimer(60);
                }
              } else {
                const result = await verifyHandler(
                  values as OTPFormType
                );

                if (result?.isError) {
                  setFormErrors({
                    isError: true,
                    isSuccess: false,
                    error: result.data as IErrorResult,
                  });
                }
              }
            }}
            onChange={resetErrors}
            submitter={{
              render: ({ submit }) => loginButton(submit),
            }}
          >
            {form}
            <Error error={formErrors.error} isError={formErrors.isError} />
          </LoginForm>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 10 }} lg={{ span: 8 }}>
          <Card
            style={{ width: "100%", height: "100%" }}
            title={
              <Space>
                <Typography.Title level={5}>Need Help? Contact us:</Typography.Title>
                <Typography.Link>{supportEmail}</Typography.Link>
              </Space>
            }
            cover={<img alt="logo" src={LoginArt} />}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Login;
