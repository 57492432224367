import Button from "antd/es/button";
import Dropdown from "antd/es/dropdown";
import Menu from "antd/es/menu";
type ActionMenuProps = {
  handleClick: (event: any) => void;
};
const ActionMenu = ({ handleClick }: ActionMenuProps) => {
  const menuItems = [{ key: "service", label: "Add a Service" }];

  const menu = <Menu onClick={handleClick} items={menuItems} />;
  return (
    <Dropdown overlay={menu} placement="bottomRight" arrow>
      <Button block type="primary" size="middle">
        Actions
      </Button>
    </Dropdown>
  );
};

export default ActionMenu;
