import Row from "antd/es/row";
import Descriptions from "antd/es/descriptions";
import { ResponseType } from "pages/ROs";
import * as api from "components/api";
import ActionMenu from "./action_menu";
import { PageHeader } from "@ant-design/pro-components";
import { Space } from "antd";


type profileHeaderProps = {
  data: ResponseType;
  options: Record<api.ILookupKeys, api.ICombo[]>;
  title: string;
  handler: (event: any) => void;
  canAdd: boolean;
};

export const ProfileHeader = ({
  data,
  title,
  handler,
  canAdd,
}: profileHeaderProps) => {
  return (
    <PageHeader
      onBack={() => window.history.back()}
      title={title}
      subTitle={""}
      extra={
        <Space>
          <ActionMenu handleClick={handler} />
        </Space>
      }
    >
      <Row>
        <Descriptions size="small" column={3}>
          <Descriptions.Item label="Counter">
            {data.hub.name}
          </Descriptions.Item>
          <Descriptions.Item label="Start Time">
            {data.startTime}
          </Descriptions.Item>
          <Descriptions.Item label="End Time">
            {data.endTime}
          </Descriptions.Item>
        </Descriptions>
      </Row>
    </PageHeader>
  );
};
