import ProTable, { ProColumns } from "@ant-design/pro-table";
import Button from "antd/es/button";
import Space from "antd/es/space";
import Popconfirm from "antd/es/popconfirm";
import tableExport from "antd-table-export";

import { ResponseType, TableProps } from "./types";
import { RoleBasedAuthorization, useAuth } from "components/auth";

const Table = (props: TableProps) => {
  const {
    state: { user },
  } = useAuth();

  let canAdd = RoleBasedAuthorization(user, ["CCo"], false);
  let canEdit = RoleBasedAuthorization(user, ["UCo"], false);
  let canDelete = RoleBasedAuthorization(user, ["DCo"], false);
  const { isLoading, total, pageSize, dataSource, SendRequestForData } = props;
  const columns: ProColumns<ResponseType>[] = [
    {
      title: "Code",
      dataIndex: "code",
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Recycle Bin",
      dataIndex: "deleted",
      valueType: "switch",
      hideInTable: true,
    },
    {
      title: "Operations",
      width: 200,
      key: "options",
      valueType: "option",
      render: (_, record) => {
        return (
          <Space size="middle">
            {canDelete && (
              <Popconfirm
                title={`Are you sure to  delete "${record.name ?? "this"
                  }" from the system?`}
                onConfirm={() => props.deleteHandler(record.code)}
                okText="Yes"
                cancelText="No"
              >
                <Button danger type="primary">
                  Delete
                </Button>
              </Popconfirm>
            )}
            {canEdit && (
              <Button onClick={() => props.editHandler(record)} type="primary">
                Edit
              </Button>
            )}
          </Space>
        );
      },
    },
  ];

  const renderedColumns = columns;
  const exportInstance = new tableExport(
    dataSource.map((v) => ({
      ...v,
      type: v.type?.name,
    })),
    renderedColumns
      // remove options, deleted and notes columns
      .filter((x) => x.dataIndex !== "Operations" && x.dataIndex !== "deleted")
      .map((x) => ({
        dataIndex: x.dataIndex as string,
        title: x.title! as string,
      }))
  );

  return (
    <ProTable<ResponseType>
      headerTitle="Companies"
      columns={renderedColumns}
      loading={isLoading}
      request={async (params = {}, sort, filter) =>
        SendRequestForData({ params, sort, filter })
      }
      onReset={() => {
        let params = { current: 1, pageSize };
        let sort = {};
        let filter = {};
        SendRequestForData({ params, sort, filter, isReset: true });
      }}
      dataSource={dataSource}
      rowKey="code"
      pagination={{
        showQuickJumper: true,
        pageSize,
        total,
      }}
      search={{ labelWidth: "auto" }}
      dateFormatter="string"
      toolBarRender={() => [
        <Button
          type="primary"
          onClick={() => exportInstance.download("Companies", "xlsx")}
        >
          Export
        </Button>,
        canAdd && (
          <Button type="primary" onClick={props.addNewHandler}>
            Add New
          </Button>
        ),
      ]}
    />
  );
};

export default Table;
