import { Routes, Route } from "react-router-dom";
import { Layout } from "components/layout";
import * as Pages from "pages";

const AppRoutes = () => {
  return (
    <Layout>
      <Routes>
        <Route path="*" element={<Pages.ErrorPages status="404" />} />
        <Route path="/" element={<Pages.HomePage />} />
        <Route path="/account" element={<Pages.MyProfile />} />
        <Route path="/settings" element={<Pages.WelcomePage page="Home" />} />
        <Route path="/settings/roles" element={<Pages.RolesPage />} />
        <Route path="/settings/templates" element={<Pages.EmailTemplate />} />
        <Route
          path="/settings/roles/details"
          element={<Pages.RoleDetailsPage />}
        />
        <Route
          path="/settings/employees/details"
          element={<Pages.EmployeeDetailsPage />}
        />

        <Route path="/settings/employees" element={<Pages.EmployeesPage />} />
        <Route path="/settings/branches" element={<Pages.BranchesPage />} />
        <Route path="/settings/services" element={<Pages.ServicesPage />} />
        <Route path="/settings/ros" element={<Pages.ROsPage />} />
        <Route path="/settings/feedbacks" element={<Pages.FeedbacksPage />} />
        <Route path="/settings/companies" element={<Pages.CompaniesPage />} />

        <Route path="/settings/departments" element={<Pages.HubsPage />} />
        <Route
          path="/settings/departments/details"
          element={<Pages.HubDetailsPage />}
        />
        <Route
          path="/settings/ros/details"
          element={<Pages.RODetailsPage />}
        />
      </Routes>
    </Layout>
  );
};
export default AppRoutes;
