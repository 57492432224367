import PageHeader from "antd/es/card";
import Tag from "antd/es/tag";
import Row from "antd/es/row";
import Space from "antd/es/space";
import Descriptions from "antd/es/descriptions";
import ActionMenu from "./action_menu";

import { ResponseType } from "pages/employees";
import * as api from "components/api";
import Button from "antd/es/button";
import BackwardOutlined from "@ant-design/icons/BackwardOutlined";

type profileHeaderProps = {
  data: ResponseType;
  options: Record<api.ILookupKeys, api.ICombo[]>;
  title: string;
  handler: (event: any) => void;
};

export const ProfileHeader = ({ data, title, handler }: profileHeaderProps) => {
  return (
    <PageHeader
      title={<Space>
        <Tag color={data.active ? "green" : "volcano"}>
          Active: {data.active ? "Yes" : "No"}
        </Tag>
        <Tag color={data.title ? "green" : "volcano"}>
          Title: {data?.title || "None"}
        </Tag>
      </Space>}
      extra={
        <Space>
          <Button onClick={() => window.history.back()} icon={<BackwardOutlined />} />
          <ActionMenu handleClick={handler} active={data.active} />
        </Space>
      }
    >
      <Row>
        <Descriptions size="small" column={3}>
          <Descriptions.Item label="Full Name">
            {data.fullName}
          </Descriptions.Item>
          <Descriptions.Item label="Email">{data.email}</Descriptions.Item>
          <Descriptions.Item label="Phone">{data.phone}</Descriptions.Item>
          <Descriptions.Item label="Sex">{data.sex.name}</Descriptions.Item>
        </Descriptions>
      </Row>
    </PageHeader>
  );
};
