import { useState } from "react";
import message from "antd/lib/message";

import * as api from "components/api";
import { ProfileHeader } from "./header";
import { ResponseType } from "pages/employees";
import DetailsTab from "./tabs";
import { useAuth } from "components/auth";
// import PositionsForm, { PositionRequestType } from "./position-form";
import ChangePasswordForm, { ActionsRequestType } from "./changePass-form";
import { PopUpActions } from "pages/common";
export type MenuActions =
  | "gPass"
  | "roles"
  | "activate"
  | "deactivate"
  | "changePass"
  | "transfer"
  | "revoke";

const lookupQueries: api.ILookupQueryProps[] = [
  {
    endpoint: "sex",
    fromLookup: false,
    isLocalEnum: true,
    id: "sex",
  },
];
type PopUpType = {
  id: number | string;
  title: string;
  visible: boolean;
  type: "activate" | "deactivate" | "revoke";
};
const Index = () => {
  const endpoint = api.Endpoints.Account.Profile;
  const {
    state: { user },
  } = useAuth();
  let Id = user?.sub;

  const [passwordForm, setPasswordForm] = useState<{
    visible: boolean;
    type: "gPass" | "roles";
    payload: ActionsRequestType;
  }>({
    visible: false,
    type: "gPass",
    payload: {
      currentPassword: "",
      newPassword: "",
    },
  });

  // const [pSForm, setPSForm] = useState({
  //   visible: false,
  //   payload: {
  //     id: 0,
  //     branchId: 0,
  //     Id: Id!,
  //   },
  //   type: "add",
  // });

  const [showPopup, setShowPopup] = useState<PopUpType>({
    id: "",
    title: "",
    visible: false,
    type: "activate",
  });
  const options = api.useLookups({ lookups: lookupQueries });
  const mutations = api.useMutator<any>();
  const { isLoading, data, mainQueryKey } =
    api.useFetchSingleItem<ResponseType>({
      endpoint,
      ids: null,
      enabled: Id !== null,
    });

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (isLoading || !data) {
    return <div>Loading...</div>;
  }
  const handlerMenuClick = ({ key }: { key: MenuActions }) => {
    switch (key) {
      case "revoke":
        setShowPopup({
          visible: true,
          id: "",
          title:
            "Are you sure to revoke all active logins related this account",
          type: "revoke",
        });
        break;
      case "gPass":
        setPasswordForm({
          visible: true,
          type: "gPass",
          payload: {
            currentPassword: "",
            newPassword: "",
          },
        });
        break;
      default:
        throw new Error("Clicked On unknown menu button");
    }
  };
  const submit = async (
    // payload: PositionRequestType | RoleRequestType
    payload: any
  ): Promise<api.ISubmissionResponse> => {
    try {
      if (passwordForm.visible) {
        const dest = api.Endpoints.Account.ChangePassword;
        return await mutations.addHandler({
          endpoint: dest,
          mainQueryKey: null,
          payload: {
            ...payload,
          },
        });
      } else {
        const dest = "";
        return await mutations.addHandler({
          endpoint: dest,
          mainQueryKey: [dest],
          payload,
        });
      }
    } catch (error) {
      return {
        isError: true,
        isSuccess: false,
        error: error as api.IErrorResult,
      };
    } finally {
      mutations.invalidateQuery(mainQueryKey);
    }
  };
  const confirmPopup = async () => {
    try {
      if (showPopup.visible) {
        switch (showPopup.type) {
          case "activate": {
            await mutations.addHandler({
              endpoint: api.Endpoints.Employee.activate(Id!),
              mainQueryKey: mainQueryKey,
            });
            message.success("Successfully completed");
            break;
          }
          case "deactivate": {
            await mutations.addHandler({
              endpoint: api.Endpoints.Employee.deactivate(Id!),
              mainQueryKey: mainQueryKey,
            });
            message.success("Successfully completed");
            break;
          }
          case "revoke": {
            await mutations.addHandler({
              endpoint: api.Endpoints.Employee.revoke(Id!),
              mainQueryKey: mainQueryKey,
            });
            message.success("Successfully completed");
            break;
          }
          default:
            break;
        }
      }
    } catch (error) {
      const err = error as api.IErrorResult;
      message.error(err.detail ?? err.title ?? "Error");
    } finally {
      setShowPopup({ ...showPopup, visible: false });
    }
  };
  const deleteHandler = async (id: number | string, type: MenuActions) => {
    try {
      switch (type) {
        case "roles": {
          const dest = api.Endpoints.Employee.removeFromRole(
            Id!,
            id.toString()
          );
          await mutations.deleteHandler({
            endpoint: dest,
            mainQueryKey: [`${api.Endpoints.Roles.main}?Id=${Id!}`, null],
          });
          message.success("Successfully completed");
          break;
        }

        default:
          break;
      }
    } catch (error) {
      const err = error as api.IErrorResult;
      message.error(err.detail ?? err.title ?? "Error");
    }
  };
  const editHandler = (payload: any, type: MenuActions) => { };
  return (
    <>
      <ProfileHeader
        title="Account"
        data={data.data}
        options={options.data}
        handler={handlerMenuClick}
      />
      <DetailsTab
        employeeId={Id!}
        lookups={{
          data: options.data,
          isLoading: isLoading,
        }}
        deleteHandler={deleteHandler}
        editHandler={editHandler}
        data={data.data}
      />
      <ChangePasswordForm
        visible={passwordForm.visible}
        title={"Roles"}
        onFinish={submit}
        onClose={() => setPasswordForm((e) => ({ ...e, visible: false }))}
      />
      {/* <PositionsForm
        lookups={options.data}
        visible={pSForm.visible}
        title={"Position"}
        onFinish={submit}
        defaultValue={pSForm.payload}
        onClose={() => setPSForm((e) => ({ ...e, visible: false }))}
      /> */}
      <PopUpActions
        closable={false}
        title={showPopup.title}
        id={showPopup.id}
        visible={showPopup.visible}
        width={340}
        visibilityChangeFunc={() =>
          setShowPopup({ ...showPopup, visible: false })
        }
        onConfirm={confirmPopup}
      />
    </>
  );
};

export default Index;
