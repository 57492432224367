import { useState } from "react";
import { DrawerForm, ProFormText, ProFormTextArea } from "@ant-design/pro-form";
import message from "antd/es/message";

import { RequestType, FormProps } from "./types";
import { ISubmissionResponse } from "components/api";
import Error from "components/ui/error";
import { ProFormSelect } from "@ant-design/pro-components";
const Form = (props: FormProps) => {
  const { state, title, visible, onVisibleChange, initialState, onFinish } =
    props;
  const [formErrors, setFormErrors] = useState<ISubmissionResponse>({
    error: null,
    isError: false,
    isSuccess: false,
  });

  const resetErrors = () => {
    if (formErrors.isError) {
      setFormErrors({
        error: null,
        isError: false,
        isSuccess: true,
      });
    }
  };

  return (
    <DrawerForm<RequestType>
      title={`${state} ${title}`}
      size={"middle"}
      width={550}
      open={visible}
      onOpenChange={onVisibleChange}
      autoFocusFirstInput
      request={async () => initialState}
      name="galawo-model-form"
      drawerProps={{
        onClose: () => onVisibleChange(false),
        destroyOnClose: true,
        closable: false,
        maskClosable: false,
      }}
      onFinish={async (values) => {
        var result = await onFinish(values);
        if (result.isSuccess) {
          message.success("Successful completed");
        }
        setFormErrors(result);
        return result.isSuccess;
      }}
      onChange={resetErrors}
      submitter={{
        searchConfig: {
          resetText: "Cancel",
          submitText: "Submit",
        },
      }}
    >
      <ProFormSelect
        name="companyId"
        label="Company"
        rules={[{ required: true }]}
        width="md"
        request={async () => props.lookups.data["companies"] ?? []}
        debounceTime={0}
        showSearch={true}
        placeholder="Please select Company"
      />
      <ProFormText
        name="name"
        label="Name"
        placeholder="Enter Name"
        rules={[{ required: true, min: 2, max: 100 }]}
        width="md"
      />
      <ProFormText
        name="code"
        label="Code"
        placeholder="Enter Code"
        rules={[{ required: true, min: 2, max: 100 }]}
        width="md"
      />
      <ProFormText
        name="address"
        label="Address"
        placeholder="Enter Address"
        rules={[{ required: false, max: 100 }]}
        width="md"
      />
      <ProFormText
        name="phone"
        label="Phone"
        placeholder="Enter Phone"
        rules={[{ required: false, max: 20 }]}
        width="md"
      />
      <ProFormText
        name="email"
        label="Email"
        placeholder="Enter Email"
        rules={[{ required: false, max: 100, type: 'email' }]}
        width="md"
      />
      <ProFormTextArea
        name="notes"
        label="Notes"
        placeholder="Enter Notes"
        rules={[{ required: false, max: 500 }]}
        width="md"
      />
      <Error error={formErrors.error} isError={formErrors.isError} />
    </DrawerForm>
  );
};

export default Form;
