import { useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import type { MenuDataItem, ProSettings } from "@ant-design/pro-layout";
import ProLayout from "@ant-design/pro-layout";
import { PopUpActions } from "pages/common";

import defaultProps from "./_defaultProps";
import RightContent from "./RightContent";
import Footer from "./Footer";
import "./layout.css";
import { ILayoutProps } from ".";
import { AuthContext } from "components/auth";
import useIdle from "./useIdleTimeout";

type PopUpType = {
  visible: boolean;
};
const Layout = (props: ILayoutProps) => {
  const { user, logoutHandler, company } = useContext(AuthContext);

  const { children } = props;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const routes = defaultProps(user);

  const [settings] = useState<Partial<ProSettings> | undefined>({
    navTheme: "light",
    layout: "mix",
    contentWidth: "Fluid",
    fixedHeader: false,
    fixSiderbar: true,
    splitMenus: false,
  });

  const [showPopup, setShowPopup] = useState<PopUpType>({
    visible: false,
  });

  const { idleTimer } = useIdle({
    onIdle: () => {
      setShowPopup({ ...showPopup, visible: true });
    },
    idleTime: 20,
  });

  const confirmPopup = () => {
    logoutHandler();
    setShowPopup({ ...showPopup, visible: false });
  };

  const menuItemClick = (item: MenuDataItem) => navigate(item.path || "/");

  return (
    <div id="dashboard-layout">
      <ProLayout
        title={`Feedback - ${company?.name}`}
        logo={company?.logo}
        {...routes}
        location={{
          pathname,
        }}
        menuItemRender={(item: any, dom: any) => (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a onClick={() => menuItemClick(item)}>{dom}</a>
        )
        }
        rightContentRender={() => (
          <RightContent
            username={user?.name ?? user?.unique_name ?? ""}
            logoutHandler={logoutHandler}
          />
        )}
        footerRender={() => <Footer />}
        {...settings}
      >
        <div className="dashboard-main">{children}</div>
      </ProLayout >
      <PopUpActions
        closable={false}
        title={
          "You have been idle for 13 minutes, you will be logged out in 7 minutes."
        }
        id={1}
        visible={showPopup.visible}
        width={340}
        visibilityChangeFunc={() => {
          idleTimer.reset();
          setShowPopup({ ...showPopup, visible: false });
        }}
        onConfirm={confirmPopup}
        okText="Logout"
        cancelText="Stay"
      />
    </div >
  );
};

export default Layout;
