import { useState } from "react";
import {
  DrawerForm,
  ProFormText,
  ProFormSelect,
} from "@ant-design/pro-form";
import message from "antd/es/message";

import { RequestType, FormProps } from "./types";
import { Endpoints, ISubmissionResponse } from "components/api";
import Error from "components/ui/error";
import { ProFormDependency, ProFormTimePicker } from "@ant-design/pro-components";
import DependentSelect from "components/ui/fields/dependent-select";
const Form = (props: FormProps) => {
  const { state, title, visible, onVisibleChange, initialState, onFinish } =
    props;
  const [formErrors, setFormErrors] = useState<ISubmissionResponse>({
    error: null,
    isError: false,
    isSuccess: false,
  });

  const resetErrors = () => {
    if (formErrors.isError) {
      setFormErrors({
        error: null,
        isError: false,
        isSuccess: true,
      });
    }
  };
  return (
    <DrawerForm<RequestType>
      title={`${state} ${title}`}
      size={"middle"}
      width={550}
      open={visible}
      onOpenChange={onVisibleChange}
      autoFocusFirstInput
      request={async () => initialState as RequestType}
      name="galawo-model-form"
      drawerProps={{
        onClose: () => onVisibleChange(false),
        destroyOnClose: true,
        closable: false,
        maskClosable: false,
      }}
      onFinish={async (values) => {
        const { ...rest } = values;
        var result = await onFinish({
          ...rest,
        });
        if (result.isSuccess) {
          message.success("Successfully completed");
        }
        setFormErrors(result);
        return result.isSuccess;
      }}
      onChange={resetErrors}
      submitter={{
        searchConfig: {
          resetText: "Cancel",
          submitText: "Submit",
        },
      }}
    >
      <ProFormSelect
        name="branchId"
        label="Branch"
        rules={[{ required: true }]}
        width="md"
        request={async () => props.lookups.data["branches"] ?? []}
        debounceTime={0}
        showSearch={true}
        placeholder="Please select a Branch"
        dependencies={["hubId"]}
      />
      <ProFormDependency name={["branchId"]}>
        {({ branchId }) => {
          return (
            <DependentSelect
              dependentTo="branchId"
              name="hubId"
              label="Department"
              rules={[{ required: true }]}
              width="md"
              debounceTime={0}
              showSearch={true}
              placeholder="Please Choose a department"
              lookupId={branchId}
              dependentLookupProps={{
                endpoint: Endpoints.Hubs.lookup,
                fromLookup: false,
                isLocalEnum: false,
                id: "branches",
                search: "branchId",
              }}
            />
          );
        }}
      </ProFormDependency>
      <ProFormText
        name="name"
        label="Name"
        placeholder="Enter Name"
        rules={[{ required: true, min: 2, max: 50 }]}
        width="md"
      />
      <ProFormText
        name="flexCubeUser"
        label="Flex Cube User"
        placeholder="Enter Flex Cube User"
        rules={[{ required: true, min: 2, max: 50 }]}
        width="md"
      />
      <ProFormText
        name="phone"
        label="Phone"
        placeholder="Enter Phone"
        rules={[{ required: true, min: 2, max: 50 }]}
        width="md"
      />
      <ProFormText
        name="email"
        label="Email"
        placeholder="Enter Email"
        rules={[{
          required: true, min: 2, max: 50, type: "email"
        }]}
        width="md"
      />
      <ProFormText
        name="jobTitle"
        label="Job Title"
        placeholder="Enter Job Title"
        rules={[{ required: false, min: 2, max: 50 }]}
        width="md"
      />
      <ProFormTimePicker
        name="startTime"
        label="Start Time"
        placeholder="Enter Start Time"
        rules={[{ required: true }]}
        width="md"
      />
      <ProFormTimePicker
        name="endTime"
        label="End Time"
        placeholder="Enter End Time"
        rules={[{ required: true }]}
        width="md"
      />
      <Error error={formErrors.error} isError={formErrors.isError} />
    </DrawerForm>
  );
};

export default Form;
export type { RequestType as RORequestType };
