import { ResponseType } from "./types";
import Modal from "antd/es/modal";
import QRCode from "antd/es/qrcode";
import Button from "antd/es/button";
import Flex from "antd/es/flex";
import Space from "antd/es/space";
import message from "antd/es/message";
type FormProps = {
  data: ResponseType;
  isOpen: boolean;
  onClose: () => void;
};

// const downloadQRCode = (name: string) => {
//   const canvas = document.getElementById('my_qr_code')?.querySelector<HTMLCanvasElement>('canvas');
//   if (canvas) {
//     const url = canvas.toDataURL();
//     const a = document.createElement('a');
//     a.download = name + ' QR Code.png';
//     a.href = url;
//     document.body.appendChild(a);
//     a.click();
//     document.body.removeChild(a);
//   }
// };

const downloadQRCode = async (name: string) => {
  const canvas = document.getElementById("my_qr_code")?.querySelector<HTMLCanvasElement>("canvas");
  if (canvas) {
    const qrCodeDataUrl = canvas.toDataURL(); // Get the QR code as an image

    // Create a new canvas for combining the background and QR code
    const combinedCanvas = document.createElement("canvas");
    const context = combinedCanvas.getContext("2d");
    if (!context) return;

    // Load the background image
    const backgroundImage = new Image();
    backgroundImage.src = `${process.env.PUBLIC_URL}/cover.jpg`;

    backgroundImage.onload = () => {
      // Set the canvas size to match the background image
      combinedCanvas.width = backgroundImage.width;
      combinedCanvas.height = backgroundImage.height;

      // Draw the background image on the canvas
      context.drawImage(backgroundImage, 0, 0);

      // Load the QR code image
      const qrCodeImage = new Image();
      qrCodeImage.src = qrCodeDataUrl;

      qrCodeImage.onload = () => {
        // Calculate the position to center the QR code
        const qrX = (combinedCanvas.width - qrCodeImage.width) / 2 - 40;
        const qrY = (combinedCanvas.height - qrCodeImage.height) / 2 - 50;

        // Define the dimensions of the white box (adjust based on your image)
        const boxWidth = 350; // Width of the white box
        const boxHeight = 350; // Height of the white box

        // Calculate the QR code dimensions to fit inside the white box
        const qrWidth = boxWidth;
        const qrHeight = boxHeight;

        // Draw the QR code within the white box
        context.drawImage(qrCodeImage, qrX, qrY, qrWidth, qrHeight);

        // Generate the final combined image as a data URL
        const finalUrl = combinedCanvas.toDataURL();

        // Trigger the download
        const a = document.createElement("a");
        a.download = name + " QR Code.jpg"; // Use JPG extension
        a.href = finalUrl;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      };
    };
  }
};

const Form = (props: FormProps) => {
  const endpoint = "https://feedback.cib.dahabtechnology.com/?token=" + props.data.token;
  const onOk = () => {
    navigator?.clipboard?.writeText(endpoint);
    message.info("Copied to clipboard");
  };
  return (
    <div id="my_qr_code">
      <Modal
        width={400}
        title={`Hub ${props.data.name} QR Code`}
        open={props.isOpen}
        onCancel={props.onClose}
        onOk={onOk}
        okText="Copy"
      >
        <Flex justify="center">
          <QRCode color="black" style={{ width: 380 }} value={endpoint} errorLevel="H" />
        </Flex>
      </Modal>
    </div>
  );
};
type SimpleViewProps = {
  data: ResponseType;
};
const SimpleView = ({ data }: SimpleViewProps) => {
  const endpoint = "https://feedback.dahabshilbank.com/?token=" + data.token;

  return (
    <Space direction="vertical" id="my_qr_code">
      <QRCode bgColor="transparent" color="black" style={{ width: 380 }} value={endpoint} />
      <Space direction="horizontal">
        <Button type="primary" onClick={() => downloadQRCode(data.name)}>
          Download
        </Button>
        <Button type="primary" onClick={() => navigator?.clipboard?.writeText(endpoint)}>
          Copy
        </Button>
      </Space>
    </Space>
  );
};

export default Form;
export { SimpleView };
