import { useQueries } from "react-query";
import { staleTime } from "assets/";
import {
  ILookup,
  IResponse,
  ILookupQueryProps,
  useAxios,
} from "components/api";

import enumValues from "assets/enumValues";

type QueriesBLProps = {
  lookups: ILookupQueryProps[];
  enabled?: boolean;
};
function QueriesBL({ lookups, enabled = true }: QueriesBLProps) {
  const axios = useAxios();
  const getDestination = (option: any) =>
    `${option.endpoint}${option.fromLookup ? "/lookup" : ""}${
      option.search ? option.search : ""
    }`;
  const optionsQueries = useQueries(
    lookups
      ?.filter((x) => !x.isLocalEnum)
      .map((option) => ({
        queryKey: ["lookups", getDestination(option)],
        queryFn: async () => {
          const { data } = await axios.get<IResponse<ILookup[]>>(
            getDestination(option)
          );
          return {
            [option.id]: data.data
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((res) => ({
                value: res.id,
                label: res.name,
                group: res.group,
                groupId: res.groupId,
                code: res.code,
                balance: res.balance,
              })),
          };
        },
        keepPreviousData: true,
        staleTime,
        cacheTime: 10000,
        retry: false,
        enabled,
      }))
  );

  let errors: any;
  let isLoading = false;
  let data: Record<string, any> = {};

  lookups
    ?.filter((x) => x.isLocalEnum)
    .forEach((v) => {
      data[v.id] = enumValues(v.endpoint)
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((res) => ({
          value: res.id,
          label: res.name,
          group: res.group,
          color: res.color,
          code: res.code,
          balance: res.balance,
        }));
    });

  const normalizeOptions = () => {
    let loading = false;
    for (let index = 0; index < optionsQueries.length; index++) {
      loading =
        optionsQueries[index].isLoading && optionsQueries[index].isFetching;

      if (optionsQueries[index].isError) {
        const error = {
          id: lookups[index].id,
          error: optionsQueries[index].error,
        };
        errors = { ...errors, error };
      }

      if (!optionsQueries[index].isLoading && !optionsQueries[index].isError) {
        data = { ...data, ...optionsQueries[index].data };
      }
    }
    return loading;
  };

  isLoading = normalizeOptions();

  return {
    isLoading,
    errors,
    data,
  };
}
export default QueriesBL;
