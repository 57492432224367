import { useContext } from "react";
import { pick } from "lodash";
import { KnownValues, AuthContext, IUser } from ".";
import { SystemRoles } from "assets";

type isAuthorizedProps = {
  value: [KnownValues];
  yes: JSX.Element;
  no: JSX.Element;
};

export const Authorize = (roles: KnownValues[]) => {
  const { user } = useContext(AuthContext);
  if (roles.includes("public")) {
    return true;
  }
  if (!user) {
    return false;
  }
  var authClaims = pick(user, ["role"]);
  if (!authClaims) {
    return false;
  }
  for (let index = 0; index < roles.length; index++) {
    const role = normalizeRole(roles[index]);
    if (authClaims.role.includes(role)) {
      return true;
    }
  }
  return false;
};

export const isAuthorized = ({ value, yes, no }: isAuthorizedProps) =>
  Authorize(value) ? yes : no;

export const RoleBasedAuthorization = (
  user: IUser | undefined,
  roles: KnownValues[],
  normalize: boolean = true
) => {
  if (roles.includes("public")) {
    return true;
  }

  if (!user) {
    return false;
  }
  var authClaims = pick(user, ["role"]);
  if (!authClaims) {
    return false;
  }

  for (let index = 0; index < roles.length; index++) {
    const role = normalize ? normalizeRole(roles[index]) : roles[index];
    const roleFound = authClaims.role.includes(role);
    if (roleFound) {
      return true;
    }
  }

  return false;
};

export const AllowedUserTypes = (user: IUser | undefined, types: number[]) => {
  if (!user) {
    return false;
  }
  if (!user.typ) {
    return false;
  }
  for (let index = 0; index < types.length; index++) {
    const role = types[index];
    if (user.typ.toString() === role.toString()) {
      return true;
    }
  }
  return false;
};

const normalizeRole = (key: string) => {
  let role = SystemRoles.find((x) => x.key === key);
  if (role) {
    return role.type;
  }
  return "none";
};
