import { useNavigate, useLocation } from "react-router-dom";

import {
  Endpoints,
  useQuery,
  useLookups,
} from "components/api";
import { IChangeRequestProps } from "pages/common";
import { useState } from "react";
import { paginateEndpoint } from "utils";
import Table from "./table";
import { ResponseType } from "./types";

const endpoint = Endpoints.Feedback.main;

const Categories = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [locationData, setLocationData] = useState<string>("");

  const { data, isLoading } = useQuery<
    ResponseType[]
  >({ endpoint });
  const options = useLookups({
    lookups: [
      {
        endpoint: Endpoints.Branches.lookup,
        fromLookup: false,
        isLocalEnum: false,
        id: "branches",
      },
      {
        endpoint: Endpoints.ROs.lookup,
        fromLookup: false,
        isLocalEnum: false,
        id: "ros",
      },
      {
        endpoint: Endpoints.Hubs.lookup,
        fromLookup: false,
        isLocalEnum: false,
        id: "hubs",
      },
    ],
    enabled: true,
  });


  const SendRequestForData = ({
    params,
    sort,
    filter,
    isReset,
  }: IChangeRequestProps) => {
    const newLocation = paginateEndpoint({
      endpoint,
      includeEndpoint: false,
      requestParams: { params, sort, filter },
      dependent: undefined,
      outsiderDependent: false,
    });

    if (locationData !== newLocation) {
      setLocationData(newLocation);
      navigate({
        pathname: pathname,
        search: newLocation,
      });
    }

    return data?.data ?? [];
  };
  return (
    <Table
      SendRequestForData={SendRequestForData}
      dataSource={data?.data ?? []}
      isLoading={isLoading}
      pageSize={data?.size ?? 10}
      total={data?.count ?? 0}
      lookups={{
        data: options.data,
        isLoading: options.isLoading,
      }}
    />
  );
};

export default Categories;
export type { ResponseType };
