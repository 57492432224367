import { useCallback } from "react";
import DashboardOutlined from "@ant-design/icons/DashboardOutlined";
import menu from "components/routes/menu";
import { IUser } from "components/auth";

const DefaultProps = (user: IUser | undefined) => {
  const routes = useCallback(() => menu(user), [user]);
  return {
    route: {
      path: "/",
      routes: [
        {
          path: "/",
          name: "Dashboard",
          icon: <DashboardOutlined />,
        },
        ...routes(),
      ],
    },
    location: {
      pathname: "/",
    },
  };
};

export default DefaultProps;
