import { useState } from "react";
import {
  DrawerForm,
  ProFormText,
  ProFormSelect,
  ProFormTextArea,
} from "@ant-design/pro-form";
import message from "antd/es/message";

import { RequestType, FormProps } from "./types";
import { ISubmissionResponse } from "components/api";
import Error from "components/ui/error";
const Form = (props: FormProps) => {
  const { state, title, visible, onVisibleChange, initialState, onFinish } =
    props;
  const [formErrors, setFormErrors] = useState<ISubmissionResponse>({
    error: null,
    isError: false,
    isSuccess: false,
  });

  const resetErrors = () => {
    if (formErrors.isError) {
      setFormErrors({
        error: null,
        isError: false,
        isSuccess: true,
      });
    }
  };
  return (
    <DrawerForm<RequestType>
      title={`${state} ${title}`}
      size={"middle"}
      width={550}
      open={visible}
      onOpenChange={onVisibleChange}
      autoFocusFirstInput
      request={async () => initialState}
      name="galawo-model-form"
      drawerProps={{
        onClose: () => onVisibleChange(false),
        destroyOnClose: true,
        closable: false,
        maskClosable: false,
      }}
      onFinish={async (values) => {
        const { ...rest } = values;
        var result = await onFinish({
          ...rest,
        });
        if (result.isSuccess) {
          message.success("Successfully completed");
        }
        setFormErrors(result);
        return result.isSuccess;
      }}
      onChange={resetErrors}
      submitter={{
        searchConfig: {
          resetText: "Cancel",
          submitText: "Submit",
        },
      }}
    >
      <ProFormText
        name="title"
        label="Title"
        placeholder="Enter Title"
        rules={[{ required: true, max: 50 }]}
        width="md"
      />
      <ProFormText
        name="firstName"
        label="First Name"
        placeholder="Enter First Name"
        rules={[{ required: true, min: 2, max: 50 }]}
        width="md"
      />
      <ProFormText
        name="lastName"
        label="Last Name"
        placeholder="Enter Last Name"
        rules={[{ required: true, min: 2, max: 50 }]}
        width="md"
      />
      <ProFormSelect
        name="fullNamePolicy"
        label="FullNamePolicy"
        rules={[{ required: true }]}
        width="md"
        request={async () => props.lookups.data["fullNamePolicies"] ?? []}
        debounceTime={0}
        showSearch={true}
        placeholder="Please select Full Name Policy"
      />
      <ProFormText
        name="email"
        label="Email"
        placeholder="Enter Email"
        rules={[{ required: true, min: 2, max: 50, type: "email" }]}
        width="md"
      />
      <ProFormText
        name="phone"
        label="Phone"
        placeholder="Enter Phone"
        rules={[{ required: true, min: 2, max: 50 }]}
        width="md"
      />
      <ProFormText
        name="username"
        label="Username"
        placeholder="Enter Username"
        rules={[{ required: false, min: 3, max: 50 }]}
        width="md"
      />
      <ProFormSelect
        name="sex"
        label="Sex"
        rules={[{ required: true }]}
        width="md"
        request={async () => props.lookups.data["sex"] ?? []}
        debounceTime={0}
        showSearch={true}
        placeholder="Please select Sex"
      />
      <ProFormText
        name="address"
        label="Address"
        placeholder="Enter Address"
        rules={[{ required: true, max: 50 }]}
        width="md"
      />
      <ProFormTextArea
        name="notes"
        label="Notes"
        placeholder="Type Notes"
        rules={[{ min: 5, max: 250, required: true }]}
        width="md"
        fieldProps={{
          rows: 4,
          autoSize: true,
          showCount: true,
        }}
      />
      <Error error={formErrors.error} isError={formErrors.isError} />
    </DrawerForm>
  );
};

export default Form;
