import { loginFormType } from "components/api/types";
import { useEffect, useState } from "react";
import auth from "./service";
import { OTPFormType } from "components/api/types/loginFormType";
const authService = new auth();
const useAuthService = () => {
  const [state, setState] = useState(authService.state);
  const [loginProgress, setLoginProgress] = useState<boolean>(false);
  const loginFC = async (payload: OTPFormType) => {
    setLoginProgress(true);
    const result = await authService.Login(payload);
    setLoginProgress(false);
    return result;
  };
  const VerifyFC = async (payload: loginFormType) => {
    setLoginProgress(true);
    const result = await authService.Verify(payload);
    setLoginProgress(false);
    return result;
  };
  useEffect(() => {
    authService.startUp(setState);
    return () => {
      authService.unMount(setState);
    };
  }, []);
  return {
    state,
    loginFC,
    logoutFC: authService.logout,
    loginProgress,
    VerifyFC,
    resendFC: authService.Resend,
  };
};

export default useAuthService;
