import jwt_decode from "jwt-decode";
import dayjs from "dayjs";
import pRetry, { AbortError } from "p-retry";
import axios, { AxiosError } from "axios";
import { tokenInStorageKey, tokenRefreshleeway, baseUrl } from "assets/";
import type { IUser, ITokens } from ".";
import { normalizeErrors } from "utils";
import type { IResponse, IErrorResult, loginFormType } from "components/api";
import { OTPFormType } from "components/api/types/loginFormType";
import message from "antd/es/message";

interface IState {
  isLoading: boolean;
  isVerified: boolean;
  username: string;
  password: string;
  isAuthenticated: boolean;
  user: IUser | undefined;
  accessToken: string;
  action: "Normal" | "ForceChange";
  connection: string;
}

class AuthService {
  _timeInterval: any;
  _stateListeners: any;
  tokens: ITokens | undefined;
  state: IState;
  constructor() {
    this.state = {
      isAuthenticated: false,
      isLoading: true,
      user: undefined,
      accessToken: "",
      isVerified: false,
      username: "",
      action: "Normal",
      password: "",
      connection: "",
    };
    this.tokens = undefined;
    this._stateListeners = [];
    this.LoadTokensFromLocalStorage();
  }

  private addStatusListener(listener: any) {
    this._stateListeners.push(listener);
  }

  //Components need to unsubscribe from changes when they unmount
  removeStatusListener(listener: any) {
    this._stateListeners = this._stateListeners.filter(
      (cb: any) => cb !== listener
    );
  }

  notifyListeners = () =>
    this._stateListeners.forEach((listener: any) => listener(this.state));

  unMount = (listener: any) => {
    clearInterval(this._timeInterval);
    this.removeStatusListener(listener);
  };

  private checkIfTokenWillExpire = () => {
    if (this.state.user) {
      return (
        dayjs.unix(this.state.user.exp - tokenRefreshleeway.sc).diff(dayjs()) <
        1
      );
    }
    return false;
  };

  private decodeToken = (): IUser | undefined => {
    try {
      if (!this.tokens) {
        return undefined;
      }
      return jwt_decode(this.tokens?.accessToken) as IUser;
    } catch {
      return undefined;
    }
  };

  // else load state from storage and check if expired
  public startUp = async (listener: any) => {
    this.addStatusListener(listener);
    if (!this.tokens) {
      return this.logout();
    }
    try {
      const isExpired = this.checkIfTokenWillExpire();
      if (isExpired) {
        this.refreshTokenRunner();
      } else {
        this.refreshedOrLogined(this.tokens);
      }
    } catch (error) {
      this.logout();
    }
  };
  private LoadTokensFromLocalStorage = () => {
    try {
      const tokens = localStorage.getItem(tokenInStorageKey);
      if (tokens) {
        const { accessToken, refreshToken, action } = JSON.parse(
          tokens
        ) as ITokens;
        if (accessToken && refreshToken) {
          this.tokens = { accessToken, refreshToken, action };
          this.state.user = this.decodeToken();
        }
      }
    } catch (error) {}
  };

  refreshTokenRunner = async () => {
    try {
      await pRetry(this.refreshToken, {
        onFailedAttempt: (error) => {
          message.error("Token is not valid");
          this.logout();
        },
        retries: 10,
      });
    } catch (error) {
      this.logout();
    }
  };

  validateTokenRunner = async () =>
    await pRetry(this.validateToken, {
      onFailedAttempt: (error) => {
        message.error("Token is not valid, please login again");
        this.logout();
      },
      retries: 10,
    });

  refreshToken = async () => {
    try {
      const { data } = await axios.post<IResponse<ITokens>>(
        `${baseUrl}/auth/refresh/`,
        this.tokens
      );
      this.refreshedOrLogined(data.data);
      message.info("Session refreshed, you can continue working");
    } catch (error) {
      const _error = normalizeErrors(error as AxiosError);
      if (_error.status === 400) {
        message.error("Token is not valid, please login again");
        throw new AbortError("Token is not valid");
      }
      throw new Error(_error.title);
    }
  };
  persistToken = (tokens: ITokens): boolean => {
    this.tokens = tokens;
    const decoded = this.decodeToken();
    if (decoded) {
      this.state = {
        isAuthenticated: true,
        isLoading: false,
        user: decoded,
        accessToken: tokens.accessToken,
        isVerified: true,
        username: "",
        password: this.state.password,
        action: tokens.action,
        connection: "",
      };
      this.setRefreshTimer(decoded.exp);
      return true;
    }
    return false;
  };
  setRefreshTimer = (exp: number) => {
    if (this._timeInterval !== undefined) {
      clearInterval(this._timeInterval);
    }
    let expiresIn = dayjs.unix(exp).diff(dayjs()) - tokenRefreshleeway.ms;
    this._timeInterval = setInterval(this.refreshTokenRunner, expiresIn);
  };
  validateToken = async (): Promise<boolean> => {
    try {
      await axios.get(
        `${baseUrl}/auth/validate?token=${this.tokens?.accessToken}`
      );
      return true;
    } catch (error) {
      const _error = normalizeErrors(error as AxiosError);
      if (_error.status === 400) {
        throw new AbortError("Token is not valid");
      }
      throw new Error(_error.title);
    }
  };

  private saveTokens = () => {
    localStorage.setItem(tokenInStorageKey, JSON.stringify(this.tokens));
  };
  refreshedOrLogined = (tokens: ITokens) => {
    var result = this.persistToken(tokens);
    if (result) {
      this.saveTokens();
      this.notifyListeners();
    }
  };
  Verify = async (
    payload: loginFormType
  ): Promise<IResponse<IErrorResult | ITokens | string>> => {
    try {
      const { data, status } = await axios.post<IResponse<string>>(
        `${baseUrl}/auth/verify`,
        payload
      );
      if (status === 200) {
        this.state.isVerified = true;
        this.state.username = payload.username;
        this.state.password = payload.password;
        this.state.connection = data.data;
        this.notifyListeners();
        return { isError: false, data: data.data };
      }
    } catch (error) {
      return { isError: true, data: normalizeErrors(error as AxiosError) };
    }
    return { isError: false, data: "" };
  };
  Resend = async (): Promise<IResponse<IErrorResult | ITokens | string>> => {
    try {
      const { data, status } = await axios.post<IResponse<string>>(
        `${baseUrl}/auth/resend`,
        {
          username: this.state.username,
          connection: this.state.connection,
        }
      );
      if (status === 200) {
        this.state.isVerified = true;
        this.state.connection = data.data;
        this.notifyListeners();
        return { isError: false, data: data.data };
      }
    } catch (error) {
      return { isError: true, data: normalizeErrors(error as AxiosError) };
    }
    return { isError: false, data: "" };
  };
  Login = async (
    payload: OTPFormType
  ): Promise<IResponse<IErrorResult | ITokens | string>> => {
    try {
      const { data, status } = await axios.post<IResponse<ITokens>>(
        `${baseUrl}/auth/login`,
        {
          ...payload,
          username: this.state.username,
        }
      );
      this.refreshedOrLogined(data.data);
      if (status === 200) {
        return { isError: false, data: data.data };
      }
    } catch (error) {
      return { isError: true, data: normalizeErrors(error as AxiosError) };
    }
    return { isError: false, data: "" };
  };

  logout = () => {
    localStorage.removeItem(tokenInStorageKey);
    this.tokens = undefined;
    this.state = {
      isLoading: false,
      isAuthenticated: false,
      user: undefined,
      accessToken: "",
      isVerified: false,
      username: "",
      action: "Normal",
      password: "",
      connection: "",
    };

    this.notifyListeners();
  };
}

export default AuthService;
