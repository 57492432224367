import Tabs from "antd/es/tabs";
import { ICombo } from "components/api";
import { MenuActions } from ".";
import PersonalInfo from "./personal-info";

import { ResponseType } from "pages/employees";

export type tapPageProps = {
  employeeId: string | null;
  lookups: { data: Record<string, ICombo[]>; isLoading: boolean };
  data: ResponseType;
  deleteHandler: (id: number | string, type: MenuActions) => void;
  editHandler: (payload: any, type: MenuActions) => void;
};

const ProfileTabs = (props: tapPageProps) => {
  return (
    <Tabs
      items={[
        {
          key: "1",
          label: "General Info",
          children: <PersonalInfo data={props.data} />,
        },
      ]}
      defaultActiveKey="1"
    />
  );
};

export default ProfileTabs;
