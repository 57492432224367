import { useNavigate, useLocation } from "react-router-dom";

import {
  Endpoints,
  ISubmissionResponse,
  useQuery,
  useLookups,
  useMutator,
  IErrorResult,
} from "components/api";
import Header from "components/ui/header";
import { IChangeRequestProps } from "pages/common";
import { useState } from "react";
import { paginateEndpoint } from "utils";
import Form from "./form";
import View from "./View";
import Table from "./table";
import { RequestType, ResponseType, FormStateProps } from "./types";

const endpoint = Endpoints.ROs.main;
const initialState: RequestType = {
  id: "",
  name: "",
  hubId: "",
  startTime: "08:00:00",
  endTime: "16:00:00",
  flexCubeUser: "",
  phone: "",
  email: "",
  jobTitle: "",
};
const Categories = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [formState, setFormState] = useState<FormStateProps>({
    visible: false,
    addForm: true,
    initialState,
  });

  const [qrCodeView, setQrCodeView] = useState<{
    data: ResponseType | null;
    isOpen: boolean;
  }>({
    data: null,
    isOpen: false,
  });

  const [locationData, setLocationData] = useState<string>("");

  const { data, isLoading, refetch, error, isError, mainQueryKey } = useQuery<
    ResponseType[]
  >({ endpoint });
  const options = useLookups({
    lookups: [
      {
        endpoint: Endpoints.Branches.lookup,
        fromLookup: false,
        isLocalEnum: false,
        id: "branches",
      },
    ],
    enabled: true,
  });
  const mutations = useMutator<RequestType | ResponseType>();

  const submit = async (payload: RequestType): Promise<ISubmissionResponse> => {
    try {
      if (formState.addForm) {
        return await mutations.addHandler({
          endpoint,
          mainQueryKey,
          payload: payload as RequestType,
        });
      } else {
        return await mutations.editHandler({
          endpoint,
          mainQueryKey: mainQueryKey,
          payload: {
            ...payload,
            id: formState.initialState.id,
          } as RequestType,
        });
      }
    } catch (error) {
      return {
        isError: true,
        isSuccess: false,
        error: error as IErrorResult,
      };
    }
  };

  const formVisibleChange = (
    visible: boolean,
    init = initialState,
    addForm = true
  ) => {
    if (formState.visible === visible) {
      return;
    }
    setFormState({
      visible,
      addForm,
      initialState: init,
    });
  };

  const editOnClickHandler = ({
    hub,
    ...rest
  }: ResponseType) =>
    formVisibleChange(
      true,
      {
        ...rest,
        hubId: hub.id.toString(),
      },
      false
    );

  const onViewHandler = (props: ResponseType) => {
    setQrCodeView({ data: props, isOpen: true });
  }

  const deleteOnClickHandler = async (id: number | string | undefined) => {
    return await mutations.deleteHandler({
      mainQueryKey: mainQueryKey,
      endpoint: endpoint + "/" + id,
    });
  };

  const SendRequestForData = ({
    params,
    sort,
    filter,
    isReset,
  }: IChangeRequestProps) => {
    const newLocation = paginateEndpoint({
      endpoint,
      includeEndpoint: false,
      requestParams: { params, sort, filter },
      dependent: undefined,
      outsiderDependent: false,
    });

    if (locationData !== newLocation) {
      setLocationData(newLocation);
      navigate({
        pathname: pathname,
        search: newLocation,
      });
    }

    return data?.data ?? [];
  };
  return (
    <>
      <Table
        SendRequestForData={SendRequestForData}
        addNewHandler={() => formVisibleChange(true)}
        dataSource={data?.data ?? []}
        deleteHandler={deleteOnClickHandler}
        editHandler={editOnClickHandler}
        viewHandler={onViewHandler}
        isLoading={isLoading}
        pageSize={data?.size ?? 10}
        total={data?.count ?? 0}
        lookups={{
          data: options.data,
          isLoading: options.isLoading,
        }}
      />
      <Form
        title="Staff"
        initialState={formState.initialState}
        lookups={{
          data: options.data,
          isLoading: options.isLoading,
        }}
        onFinish={submit as any}
        onVisibleChange={(visible) => formVisibleChange(visible)}
        state={formState.addForm ? "Add New" : "Edit"}
        visible={formState.visible}
      />
      {
        qrCodeView.isOpen && (
          <View
            data={qrCodeView.data as ResponseType}
            isOpen={qrCodeView.isOpen}
            onClose={() => setQrCodeView({ data: qrCodeView.data, isOpen: false })}
          />
        )
      }
    </>
  );
};

export default Categories;
export type { ResponseType, RequestType };
