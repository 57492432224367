import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import ProTable, { ProColumns } from "@ant-design/pro-table";
import Button from "antd/es/button";
import Space from "antd/es/space";
import Popconfirm from "antd/es/popconfirm";
import EditButton from "@ant-design/icons/EditOutlined";
import EyeOutlined from "@ant-design/icons/EyeOutlined";
import DeleteButton from "@ant-design/icons/DeleteOutlined";
import tableExport from "antd-table-export";

import { ResponseType, TableProps } from "./types";
import { ICombo, ILookupKeys } from "components/api";
import { RoleBasedAuthorization, useAuth } from "components/auth";

const Table = (props: TableProps) => {
  const navigate = useNavigate();
  const {
    state: { user },
  } = useAuth();

  let canAdd = RoleBasedAuthorization(user, ["CH"], false);
  let canEdit = RoleBasedAuthorization(user, ["UH"], false);
  let canDelete = RoleBasedAuthorization(user, ["DH"], false);

  const { isLoading, total, pageSize, dataSource, SendRequestForData } = props;
  const columns = useCallback(
    (
      lookups: Record<ILookupKeys, ICombo[]>,
      isLoading: boolean
    ): ProColumns<ResponseType>[] => {
      if (isLoading) {
        return [];
      }
      return [
        {
          title: "Name",
          dataIndex: "name",
          hideInSearch: false,
        },
        {
          title: "Branch",
          dataIndex: "branch",
          valueType: "select",
          request: async () => lookups["branches"],
          render: (_, { branch }) => branch.name,
          order: 1,
          debounceTime: 0,
          fieldProps: {
            showSearch: true,
          },
        },
        {
          title: "Recycle Bin",
          dataIndex: "deleted",
          valueType: "switch",
          hideInTable: true,
        },
        {
          title: "Operations",
          width: 200,
          key: "options",
          valueType: "option",
          render: (_, record) => {
            return (
              <Space size="middle">
                <Button
                  onClick={() =>
                    navigate({ pathname: "details", search: `id=${record.id}` })
                  }
                  icon={<EyeOutlined />}
                  type="default"
                />
                {canDelete && (
                  <Popconfirm
                    title={`Are you sure to  delete "${record?.name ?? "this"
                      }" from the system?`}
                    onConfirm={() => props.deleteHandler(record.id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      danger
                      type="primary"
                      icon={<DeleteButton />}
                      title="Delete"
                    />
                  </Popconfirm>
                )}
                {canEdit && (
                  <Button
                    onClick={() => props.editHandler(record)}
                    type="primary"
                    icon={<EditButton />}
                    title="Edit"
                  />
                )}

              </Space>
            )
          },
        },
      ];
    },
    [canDelete, canEdit, navigate, props]
  );
  const renderedColumns = columns(props.lookups.data, props.lookups.isLoading);

  const exportInstance = new tableExport(
    dataSource.map((v) => ({
      ...v,
      branch: v?.branch?.name,
    })),
    renderedColumns
      // remove options, deleted and notes columns
      .filter((x) => x.dataIndex !== "Operations" && x.dataIndex !== "deleted" && x.dataIndex !== "notes")
      .map((x) => ({
        dataIndex: x.dataIndex as string,
        title: x.title! as string,
      }))
  );
  return (
    <ProTable<ResponseType>
      headerTitle="Departments"
      columns={renderedColumns}
      loading={isLoading}
      request={async (params = {}, sort, filter) =>
        SendRequestForData({ params, sort, filter })
      }
      onReset={() => {
        let params = { current: 1, pageSize };
        let sort = {};
        let filter = {};
        SendRequestForData({ params, sort, filter, isReset: true });
      }}
      dataSource={dataSource}
      rowKey="id"
      pagination={{
        showQuickJumper: true,
        pageSize,
        total,
        pageSizeOptions: ["10", "50", "100", "200", "300", "400", "500"],
      }}
      search={{ labelWidth: "auto" }}
      dateFormatter="string"
      toolBarRender={() => [
        <Button
          type="primary"
          onClick={() => exportInstance.download("Branches List", "xlsx")}
        >
          Export
        </Button>,
        canAdd && (
          <Button type="primary" onClick={props.addNewHandler}>
            Add New
          </Button>
        ),
      ]}
    />
  );
};

export default Table;
