import Tabs from "antd/es/tabs";
import { ICombo } from "components/api";
import { MenuActions } from ".";
import PersonalInfo from "./personal-info";
import RolesTable from "./roles-table";

import { ResponseType } from "pages/employees";

export type tapPageProps = {
  employeeId: string | null;
  lookups: { data: Record<string, ICombo[]>; isLoading: boolean };
  data: ResponseType;
  deleteHandler: (id: number | string, type: MenuActions) => void;
  editHandler: (payload: any, type: MenuActions) => void;
};

const ProfileTabs = (props: tapPageProps) => {
  const tableProps = {
    employeeId: props.employeeId,
    lookups: props.lookups,
    deleteHandler: props.deleteHandler,
    editHandler: props.editHandler,
  };
  return (
    <Tabs
      defaultActiveKey="1"
      items={[
        {
          key: "1",
          label: "General Info",
          children: <PersonalInfo data={props.data} />,
        },
        {
          key: "11",
          label: "Roles",
          children: <RolesTable {...tableProps} />,
        },
      ]}
    />
  );
};

export default ProfileTabs;
