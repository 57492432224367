export const Endpoints = {
  Branches: {
    main: "branches",
    lookup: "branches/lookup",
  },
  Services: {
    main: "services",
    lookup: "services/lookup",
  },
  Companies: {
    main: "companies",
    lookup: "companies/lookup",
  },
  ROs: {
    main: "ros",
    lookup: "ros/lookup",
  },
  Feedback: {
    main: "feedbacks",
    lookup: "feedbacks/lookup",
    form: "feedbacks/form",
  },
  Hubs: {
    main: "hubs",
    lookup: "hubs/lookup",
    services: "hubs/services",
    RemoveServices: (id: string, serviceId: string) =>
      `hubs/${id}/services/${serviceId}`,
  },
  Statuses: {
    main: "goalStatuses",
    lookup: "goalStatuses/lookup",
  },
  Items: {
    main: "items",
    lookup: "items/lookup",
  },
  Regions: {
    main: "regions",
    lookup: "regions/lookup",
  },
  Templates: {
    main: "templates",
    lookup: "templates/lookup",
  },
  Achievements: {
    main: "achievements",
  },
  Goals: {
    main: "projects",
    lookup: "projects/lookup",
    chunks: "projects/chunks",
    chunksLookup: "projects/chunks/lookup",
    updateChunk: "projects/GoalChunk",
  },
  Dashboard: {
    main: "Dashboard",
    employee: "Dashboard/Employee",
    goal: "Dashboard/Goal",
    topPerformers: "Dashboard/topPerformers",
    lowPerformers: "Dashboard/lowPerformers",
  },
  Account: {
    Profile: "auth",
    ChangePassword: "auth/changePassword",
    UpdatePassword: "auth/UpdatePassword",
    UpdateProfile: "auth/UpdateProfile",
  },
  Bank: {
    main: "bank",
    lookup: "bank/lookup",
  },
  Customers: {
    main: "customers",
    documents: "customers/documents",
    units: "customerUnits",
    deleteDocument: (id: string, documentId: string) =>
      `customers/${id}/documents/${documentId}`,
    lookup: "customers/lookup",
  },
  CustomerUnits: {
    main: "customerUnits",
    reserve: "customerUnits/reserve",
    sold: "customerUnits/sold",
    book: "customerUnits/book",
    cancel: "customerUnits/cancel",
    handover: "customerUnits/handover",
    return: "customerUnits/return",
  },
  Units: {
    main: "units",
    lookup: "units/lookup",
  },
  Bookings: {
    main: "bookings",
    lookup: "bookings/lookup",
  },
  BankAccounts: {
    main: "accounts",
    lookup: "accounts/lookup",
  },
  Transactions: {
    main: "transactions",
  },
  Categories: {
    main: "categories",
    lookup: "categories/lookup",
  },
  Departments: {
    main: "departments",
    lookup: "departments/lookup",
  },
  Roles: {
    main: "roles",
    lookup: "roles/lookup",
    claims: (id: string) => `roles/${id}/claims`,
    removeClaims: (roleid: string, claimId: string) =>
      `roles/${roleid}/claims/${claimId}`,
    bulkClaims: "roles/Claims/Bulk",
  },
  Permissions: {
    lookup: "permissions/lookup",
  },
  Notifications: {
    main: "notifications",
  },
  Employee: {
    main: "employees",
    lookup: "employees/lookup",
    transfer: "employees/transfer",
    tokens: "employees/tokens",
    history: (id: string) => `employees/${id}/history`,
    documents: "employees/documents",
    accounts: "employees/accounts",
    createAccount: (id: string) => `employees/${id}/account`,
    addToRole: (id: string) => `employees/${id}/roles`,
    removeFromRole: (id: string, roleId: string) =>
      `employees/${id}/roles/${roleId}`,
    job: (id: string) => `employees/${id}/job`,
    createPassword: (id: string) => `employees/${id}/createPassword`,
    activate: (id: string) => `employees/${id}/activate`,
    revoke: (id: string) => `employees/${id}/revoke`,
    deactivate: (id: string) => `employees/${id}/deactivate`,
    changePassword: "employees/changePassword",
    deleteAddress: (id: string, addressId: string) =>
      `employees/${id}/Address/${addressId}`,
    deleteAccount: (id: string, accountId: string) =>
      `employees/${id}/Account/${accountId}`,
  },
  Teams: {
    main: "teams",
    lookup: "teams/lookup",
  },
  Donors: {
    main: "donors",
    lookup: "donors/lookup",
    Accountslookup: "donors/account",
  },
  Jobs: {
    main: "jobs",
    lookup: "jobs/lookup",
  },
  Sectors: {
    main: "sectors",
    lookup: "sectors/lookup",
  },
  Sites: {
    main: "sites",
    lookup: "sites/lookup",
  },
  Partners: {
    main: "partners",
    lookup: "partners/lookup",
  },
  Projects: {
    main: "projects",
    lookup: "projects/lookup",
    employees: (id: string | number) => `projects/${id}/employees`,
    sites: (id: string | number) => `projects/${id}/sites`,
    activities: (id: string | number) => `projects/${id}/activities`,
    activitiesAction: (id: string | number, activityId: number) =>
      `projects/${id}/activities/${activityId}`,
    activitiesLookup: "projects/activities/Lookup",
  },

  Orders: {
    main: "orders",
    lookup: "orders/lookup",
    actions: (id: string | number) => `orders/${id}/actions`,
    bids: (id: string | number) => `orders/${id}/bids`,
    Inventories: (id: string | number) => `orders/${id}/inventory`,
    approve: (id: string | number, bidId: number) =>
      `orders/${id}/bids/${bidId}`,
    approveBid: (id: string) => `orders/${id}/bids/Approve`,
  },
  Supplier: {
    main: "suppliers",
    lookup: "suppliers/lookup",
  },
  DeductionTypes: {
    main: "deductiontypes",
    lookup: "deductiontypes/lookup",
  },
  OverTimeTypes: {
    main: "overtimetypes",
    lookup: "overtimetypes/lookup",
  },
  HumanRelations: {
    main: "humanrelations",
    lookup: "humanrelations/lookup",
  },
  AllowanceTypes: {
    main: "allowancetypes",
    lookup: "allowancetypes/lookup",
  },
  UnitTypes: {
    main: "unitTypes",
    lookup: "unitTypes/lookup",
  },
  DocumentTypes: {
    main: "documentTypes",
    lookup: "documentTypes/lookup",
  },
  LeaveTypes: {
    main: "leavetypes",
    lookup: "leavetypes/lookup",
  },
  EmergencyContacts: {
    main: "emergencycontacts",
  },
  Leaves: {
    main: "leaves",
  },
  OverTimes: {
    main: "overtimes",
  },
  Allowances: {
    main: "allowances",
  },
  Deductions: {
    main: "deductions",
  },
  Positions: {
    main: "positions",
  },
  Contracts: {
    main: "contracts",
    lookup: "contracts/lookup",
    documents: "contracts/documents",
    deleteDocument: (id: string, documentId: string) =>
      `contracts/${id}/documents/${documentId}`,
  },
  Receipts: {
    main: "receipts",
  },
  Payments: {
    main: "payments",
  },
  PaymentTypes: {
    main: "paymentTypes",
    lookup: "paymentTypes/lookup",
  },
};
