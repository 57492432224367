import { useState } from "react";
import { ModalForm, ProFormSelect } from "@ant-design/pro-form";
import message from "antd/es/message";

import Error from "components/ui/error";
import { ICombo, ISubmissionResponse } from "components/api";

export type EmailRequestType = {
  employeeId: string;
  // passwordConfirmation: string;
};

type FormProps = {
  visible: boolean;
  onClose: (visible: boolean) => void;
  lookups: Record<string, ICombo[]>;
  onFinish: (values: EmailRequestType) => Promise<ISubmissionResponse>;
  title: string;
};

const ActionsForm = (props: FormProps) => {
  const { visible, onClose, onFinish } = props;
  const [formErrors, setFormErrors] = useState<ISubmissionResponse>({
    error: null,
    isError: false,
    isSuccess: false,
  });

  const resetErrors = () => {
    if (formErrors.isError) {
      setFormErrors({
        error: null,
        isError: false,
        isSuccess: true,
      });
    }
  };

  return (
    <ModalForm<EmailRequestType>
      title={props.title}
      size={"middle"}
      width={400}
      open={visible}
      autoFocusFirstInput
      request={async () => ({ employeeId: "" })}
      name="galawo-model-form"
      modalProps={{
        onCancel: () => onClose(false),
        destroyOnClose: true,
        closable: false,
        maskClosable: false,
      }}
      onFinish={async (values) => {
        var result = await onFinish(values);
        setFormErrors(result);
        if (result.isSuccess) {
          message.success("Successfully completed");
          onClose(false);
        }
        return result.isSuccess;
      }}
      onChange={resetErrors}
      submitter={{
        searchConfig: {
          resetText: "Cancel",
          submitText: "Submit",
        },
      }}
    >
      <ProFormSelect
        name="templateId"
        label="Email Template"
        rules={[{ required: true }]}
        width="md"
        request={async () => props.lookups.templates ?? []}
        debounceTime={0}
        showSearch={true}
        placeholder="Please select an Email Template"
      />
      <Error error={formErrors.error} isError={formErrors.isError} />
    </ModalForm>
  );
};

export default ActionsForm;
