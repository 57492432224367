import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import ProTable, { ProColumns } from "@ant-design/pro-table";
import Button from "antd/es/button";
import Space from "antd/es/space";
import Popconfirm from "antd/es/popconfirm";
import tableExport from "antd-table-export";

import { ResponseType, TableProps } from "./types";
import { ICombo, ILookupKeys } from "components/api";
import { RoleBasedAuthorization, useAuth } from "components/auth";

const Table = (props: TableProps) => {
  const {
    state: { user },
  } = useAuth();

  let canAdd = RoleBasedAuthorization(user, ["CR"], false);
  let canEdit = RoleBasedAuthorization(user, ["UR"], false);
  let canDelete = RoleBasedAuthorization(user, ["RR"], false);
  const { isLoading, total, pageSize, dataSource, SendRequestForData } = props;
  const navigate = useNavigate();
  const columns = useCallback(
    (
      lookups: Record<ILookupKeys, ICombo[]>,
      isLoading: boolean
    ): ProColumns<ResponseType>[] => {
      if (isLoading) {
        return [];
      }
      return [
        {
          title: "Name",
          dataIndex: "name",
          order: 0,
        },
        {
          title: "Operations",
          width: 200,
          key: "options",
          valueType: "option",
          render: (_, record) => {
            return (
              <Space size="middle">
                <Button
                  onClick={() =>
                    navigate({ pathname: "details", search: `id=${record.id}` })
                  }
                  type="default"
                >
                  View
                </Button>
                {canDelete && (
                  <Popconfirm
                    title={`Are you sure to  delete "${record.name ?? "this"
                      }" from the system?`}
                    onConfirm={() => props.deleteHandler(record.id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button danger type="primary">
                      Delete
                    </Button>
                  </Popconfirm>
                )}
                {canEdit && (
                  <Button
                    onClick={() => props.editHandler(record)}
                    type="primary"
                  >
                    Edit
                  </Button>
                )}
              </Space>
            );
          },
        },
      ];
    },
    [canDelete, canEdit, navigate, props]
  );
  const renderedColumns = columns(props.lookups.data, props.lookups.isLoading);

  const exportInstance = new tableExport(
    dataSource.map((v) => ({
      ...v,
    })),
    renderedColumns
      .filter((x) => x.key !== "options")
      .map((x) => ({
        dataIndex: x.dataIndex as string,
        title: x.title! as string,
      }))
  );
  return (
    <ProTable<ResponseType>
      headerTitle="Roles List"
      columns={renderedColumns}
      loading={isLoading}
      request={async (params = {}, sort, filter) =>
        SendRequestForData({ params, sort, filter })
      }
      onReset={() => {
        let params = { current: 1, pageSize };
        let sort = {};
        let filter = {};
        SendRequestForData({ params, sort, filter, isReset: true });
      }}
      dataSource={dataSource}
      rowKey="id"
      pagination={{
        showQuickJumper: true,
        pageSize,
        total,
      }}
      search={{ labelWidth: "auto" }}
      dateFormatter="string"
      toolBarRender={() => [
        <Button
          type="primary"
          onClick={() => exportInstance.download("Roles List", "xlsx")}
        >
          Export
        </Button>,
        canAdd && (
          <Button type="primary" onClick={props.addNewHandler}>
            Add New
          </Button>
        ),
      ]}
    />
  );
};

export default Table;
