import { useAuth, AuthProvider, ICompany } from "components/auth";
import {
  ChangePassword,
  LoadingPage,
  LoginPage,
  NoAuthorizePage,
} from "pages/common";
import Routes from "components/routes";
import { getCompany } from 'Company';
import { useEffect, useState } from "react";

const App = () => {

  useEffect(() => {
    const fetchCompany = async () => {
      var { data } = getCompany();
      setCompany(data);
    }
    fetchCompany();
  }, [])

  const { state, loginFC, VerifyFC, loginProgress, logoutFC, resendFC } = useAuth();
  const showLoading = state.isLoading && !state.isAuthenticated;
  const showLogin = !state.isLoading && !state.isAuthenticated;
  const [company, setCompany] = useState<ICompany | null>(null);
  if (showLoading) {
    return <LoadingPage />;
  }

  if (showLogin) {
    return (
      <LoginPage
        loading={loginProgress}
        loginHandler={VerifyFC}
        verifyHandler={loginFC}
        resendHandler={resendFC}
        type={state.isVerified ? "login" : "verify"}
        logo={company?.logo}
        title={company?.name ?? ""}
        supportEmail={company?.email ?? ""}
      />
    );
  }

  if (state.user && !state.user.role) {
    return <NoAuthorizePage actionHandler={logoutFC} actionTitle="Logout" />;
  }

  if (state.action === "ForceChange") {
    return (
      <AuthProvider
        value={{
          accessToken: state.accessToken,
          isAuthenticated: state.isAuthenticated,
          user: state.user,
          logoutHandler: logoutFC,
          company: company
        }}
      >
        <ChangePassword logoutHandler={logoutFC} currentPassword={state.password} />
      </AuthProvider>
    )
  }

  return (
    <AuthProvider
      value={{
        accessToken: state.accessToken,
        isAuthenticated: state.isAuthenticated,
        user: state.user,
        logoutHandler: logoutFC,
        company: company
      }}
    >
      <Routes />
    </AuthProvider>
  );
};

export default App;
