import Tabs from "antd/es/tabs";
import { ICombo } from "components/api";
import ServicesTable from "./services-table"
import { MenuActions } from "./index";

export type tapPageProps = {
  roId: string | null;
  lookups: { data: Record<string, ICombo[]>; isLoading: boolean };
  queryKey?: any;
  editHandler: (key: any, payload: any) => void;
  deleteHandler: (id: string, type: MenuActions) => void;
};

const ProfileTabs = (props: tapPageProps) => {
  return (
    <Tabs
      defaultActiveKey="1"
      items={[
        {
          key: "1",
          label: "Services",
          children: (
            <ServicesTable
              queryKey={props.queryKey}
              lookups={props.lookups}
              roId={props.roId}
              editHandler={props.editHandler}
              deleteHandler={props.deleteHandler}
            />
          ),
        },
      ]}
    />
  );
};

export default ProfileTabs;
