import { useQuery } from "react-query";
import { staleTime } from "assets/";
import { IResponse, useAxios } from "components/api";

type ProfileBLProps = {
  endpoint: string;
  enabled: boolean;
};
function QueryWithCompleteEndpoint<T>({ endpoint, enabled }: ProfileBLProps) {
  const axios = useAxios();
  const mainQueryKey = [endpoint];

  const { isLoading, isError, data, error, isFetching, remove } = useQuery(
    mainQueryKey,
    async () => {
      const { data } = await axios.get<IResponse<T>>(`/${endpoint}`);
      return data.data;
    },
    {
      keepPreviousData: true,
      enabled: enabled,
      retry: false,
      staleTime: staleTime,
    }
  );

  return {
    isLoading,
    isError,
    data,
    error,
    isFetching,
    remove,
  };
}
export default QueryWithCompleteEndpoint;
