import { ICombo, ILookup, ISubmissionResponse } from "components/api";
import { IChangeRequestProps } from "pages/common";
export type RequestType = {
  id?: string;
};

export const starEquivalentDescriptions = [
  "Unacceptable",
  "Poor",
  "Average",
  "Acceptable",
  "Outstanding",
];

export type ResponseType = {
  id: string;
  ro: ILookup;
  hub: ILookup;
  branch: ILookup;
  note: string;
  note2: string;
  rate: number;
  date: Date;
  accountNo: string;
};

export type FormStateProps = {
  visible: boolean;
  addForm: boolean;
  initialState: RequestType;
};

export type FormProps = {
  title: string;
  visible: boolean;
  onVisibleChange: (visible: boolean) => void;
  lookups: {
    data: Record<string, ICombo[]>;
    isLoading: boolean;
  };
  initialState: RequestType;
  onFinish: (values: RequestType) => Promise<ISubmissionResponse>;
  state: "Add New" | "Edit";
};

export type TableProps = {
  isLoading: boolean;
  pageSize: number;
  total: number;
  dataSource: ResponseType[];
  SendRequestForData: (props: IChangeRequestProps) => ResponseType[];
  lookups: {
    data: Record<string, ICombo[]>;
    isLoading: boolean;
  };
};
