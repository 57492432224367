import { ParamsType } from "@ant-design/pro-provider";
import { SortOrder } from "antd/lib/table/interface";
import { SystemRoles, toAddIdSuffix } from "assets";
import { ILookup } from "components/api";
import { IChangeRequestProps } from "pages/common";
export function instanceOfILookup(object: any): object is ILookup {
  return object.discriminator === "ILookup";
}

type paginateEndpointProps = {
  endpoint: string;
  includeEndpoint: boolean;
  requestParams: IChangeRequestProps;
  dependent?: { key: string | undefined; value: any };
  outsiderDependent: boolean;
};
const isValid = (value: any) =>
  value !== null && value !== undefined && value !== "";

const addSuffixToKeys = (key: string) =>
  !toAddIdSuffix.includes(key) ? key : key + "Id";

const createSearchParams = (obj: ParamsType) => {
  const search = Object.keys(obj).map((key) => {
    const searchKey = addSuffixToKeys(key);
    return isValid(obj[key]) ? `${searchKey}=${obj[key]}` : null;
  });
  return search.filter(isValid).join("&");
};

const createSortParams = (obj: Record<string, SortOrder> | undefined) => {
  if (obj === undefined) {
    return;
  }
  return Object.keys(obj).map((key) => {
    return obj[key] ? `&sort=${key}_${obj[key]}` : null;
  });
};

export const paginateEndpoint = ({
  endpoint,
  requestParams,
  includeEndpoint,
  dependent,
  outsiderDependent,
}: paginateEndpointProps) => {
  const { params, sort } = requestParams;
  const { current, keyword, pageSize, ...rest } = params;
  if (outsiderDependent && dependent && dependent.key) {
    rest[dependent.key] = dependent.value;
  } else if (!outsiderDependent && dependent && dependent.key) {
    // to persist location with url search
    if (!rest[dependent.key]) {
      rest[dependent.key] = dependent.value;
    }
  }
  const makeSearch = createSearchParams(rest);
  const sortParam = createSortParams(sort);

  let searchParams = makeSearch ? "&" + makeSearch : "";
  searchParams += sortParam ? "" + sortParam : "";

  return includeEndpoint
    ? `/${endpoint}?size=${pageSize}&index=${current}${searchParams}`
    : `?size=${pageSize}&index=${current}${searchParams}`;
};

export const normalizeClaims = (
  type: "Key" | "Type",
  value: string,
  normalize: boolean = false
) => {
  if (type === "Key") {
    let key = SystemRoles.find((x) => x.type === value)?.key;

    if (normalize) {
      return key?.replace(/[A-Z]/g, (m) => " " + m) ?? "";
    }
    return key ?? "";
  }
  return SystemRoles.find((x) => x.key === value)?.type ?? "";
};

export const tranformEndpointForFetcherUsingRoutes = (
  endpoint: string,
  routes: string[] | null
) => {
  if (routes) {
    return `${endpoint}/${routes.join("/")}`;
  }
  return endpoint;
};

export const formatNumbersWithComas = (value: string) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
export const formatAsFormData = <T>(
  payload: T
): {
  data: FormData;
  headers: { "Content-Type": string };
} => {
  const form = new FormData();

  for (const key in payload) {
    form.append(key, (payload as any)[key]);
  }

  return {
    data: form,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
};

export const MappingColors = (color: string) => {
  switch (color) {
    case "red":
      return "#FF4D4F";
    case "green":
      return "#87d068";
    case "yellow":
      return "#FFBF00";
    default:
      return color;
  }
};

export const transformFileSize = (size: number) => {
  const units = ["B", "KB", "MB", "GB", "TB"];

  let i = 0;
  while (size >= 1024) {
    size /= 1024;
    ++i;
  }

  return `${size.toFixed(1)} ${units[i]}`;
};

export const normalizeFileExtension = (extension: string) => {
  const extensions = [".pdf", ".doc", ".docx", ".jpg", ".jpeg", ".png"];
  const normalized = ["PDF", "DOC", "DOCX", "JPG", "JPEG", "PNG"];

  const index = extensions.indexOf(extension);
  return index !== -1 ? normalized[index] : extension;
};
