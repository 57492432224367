import { useContext, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { AuthContext } from "components/auth";
/**
 * @param onIdle - function to notify user when idle timeout is close
 * @param idleTime - number of minutes to wait before user is logged out
 */
const useIdleTimeout = ({
  onIdle,
  idleTime = 1,
}: {
  onIdle: VoidFunction;
  idleTime: number;
}) => {
  const idleTimeout = 1000 * idleTime * 60;

  const [isIdle, setIdle] = useState(false);
  const { logoutHandler } = useContext(AuthContext);
  const handleIdle = () => {
    setIdle(true);
    logoutHandler();
  };
  const idleTimer = useIdleTimer({
    timeout: idleTimeout,
    promptBeforeIdle: (idleTimeout / 3) * 2,
    onPrompt: onIdle,
    onIdle: handleIdle,
    debounce: 500,
  });
  return {
    isIdle,
    setIdle,
    idleTimer,
  };
};
export default useIdleTimeout;
