import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClient, QueryClientProvider } from "react-query";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ConfigProvider from "antd/es/config-provider";
import enUS from "antd/lib/locale/en_US";
const queryClient = new QueryClient();

const container = document.getElementById("app");
const root = createRoot(container!);

root.render(
  <Router>
    <QueryClientProvider client={queryClient}>
      <ConfigProvider locale={enUS} >
        <App />
      </ConfigProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  </Router>
);
reportWebVitals();
