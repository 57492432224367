import Tabs from "antd/es/tabs";
import { ICombo } from "components/api";
import ServicesTable from "./services-table"
import RosTable from "./ros-table"
import { MenuActions } from "./index";

export type tapPageProps = {
  hubId: string | null;
  lookups: { data: Record<string, ICombo[]>; isLoading: boolean };
  queryKey?: any;
  editHandler: (key: any, payload: any) => void;
  deleteHandler: (id: string, type: MenuActions) => void;
};

const ProfileTabs = (props: tapPageProps) => {
  return (
    <Tabs
      defaultActiveKey="1"
      items={[
        {
          key: "1",
          label: "Services",
          children: (
            <ServicesTable
              queryKey={props.queryKey}
              lookups={props.lookups}
              hubId={props.hubId}
              editHandler={props.editHandler}
              deleteHandler={props.deleteHandler}
            />
          ),
        },
        {
          key: "2",
          label: "Staff",
          children: (
            <RosTable
              queryKey={props.queryKey}
              lookups={props.lookups}
              hubId={props.hubId}
              editHandler={props.editHandler}
              deleteHandler={props.deleteHandler}
            />
          ),
        },
      ]}
    />
  );
};

export default ProfileTabs;
