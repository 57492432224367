import { useState } from "react";
import { DrawerForm, ProFormSelect, ProFormText, ProFormUploadDragger } from "@ant-design/pro-form";
import message from "antd/es/message";

import { RequestType, FormProps } from "./types";
import { ISubmissionResponse } from "components/api";
import Error from "components/ui/error";

const Form = (props: FormProps) => {
  const { state, title, visible, onVisibleChange, initialState, onFinish } =
    props;
  const [formErrors, setFormErrors] = useState<ISubmissionResponse>({
    error: null,
    isError: false,
    isSuccess: false,
  });
  const [fileString, setFileString] = useState<string>("");
  const beforeUpload = (file: any) => {
    convertFileToBase64String(file);
    return false;
  };
  const resetErrors = () => {
    if (formErrors.isError) {
      setFormErrors({
        error: null,
        isError: false,
        isSuccess: true,
      });
    }
  };

  const convertFileToBase64String = (file: any) => {
    if (file) {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        let result: string = e?.target?.result;
        setFileString(result.substring(result.lastIndexOf(',') + 1));
      };
      reader.readAsDataURL(file);
    }
  }

  return (
    <DrawerForm<RequestType>
      title={`${state} ${title}`}
      size={"middle"}
      width={550}
      open={visible}
      onOpenChange={onVisibleChange}
      autoFocusFirstInput
      request={async () => initialState}
      name="galawo-model-form"
      drawerProps={{
        onClose: () => onVisibleChange(false),
        destroyOnClose: true,
        closable: false,
        maskClosable: false,
      }}
      onFinish={async (values) => {
        var result = await onFinish({
          ...values,
          file: fileString,
          name: values.description?.trim() + ".html"
        });
        if (result.isSuccess) {
          message.success("Successfully completed");
        }
        setFormErrors(result);
        return result.isSuccess;
      }}
      onChange={resetErrors}
      submitter={{
        searchConfig: {
          resetText: "Cancel",
          submitText: "Submit",
        },
      }}
    >
      <ProFormSelect
        name="type"
        label="Email Template Type"
        rules={[{ required: true }]}
        width="md"
        request={async () => props.lookups.emailType ?? []}
        debounceTime={0}
        showSearch={true}
        placeholder="Please Template Type"
      />
      <ProFormText
        name="description"
        label="Name"
        placeholder="Enter File Name"
        rules={[{ required: true, min: 2, max: 50 }]}
        width="md"
      />
      <ProFormUploadDragger
        rules={[{ required: true }]}
        fieldProps={{ beforeUpload, multiple: false }}
        title="Choose Email Template"
        description="Only HTML Files Are Allowed"
        label="Email Template"
        name="file"
        action=""
        accept=".html, .html" />
      <Error error={formErrors.error} isError={formErrors.isError} />
    </DrawerForm>
  );
};

export default Form;
