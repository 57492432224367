import { createContext } from "react";
export interface ICompany {
  id: string;
  name: string;
  logo: string;
  email: string;
}
interface IAuthState {
  user: any;
  accessToken: string;
  isAuthenticated: boolean;
  logoutHandler: () => void;
  company: ICompany | null;
}

const AuthContext = createContext<IAuthState>({
  user: null,
  accessToken: "",
  isAuthenticated: false,
  logoutHandler: () => {},
  company: null,
});

export const AuthProvider = AuthContext.Provider;
export const AuthConsumer = AuthContext.Consumer;
export { AuthContext };
